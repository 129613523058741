import Vue from "vue";
import Router from "vue-router";
import MainNavbar from "./layout/MainNavbar.vue";
import MainFooter from "./layout/MainFooter.vue";

import ComingSoon from "./pages/ComingSoon.vue";
import Home from "./pages/Home.vue";
import SearchResults from "./pages/SearchResults.vue";
import error404 from "./pages/404page.vue";
import Contact from "./pages/Contact.vue";
import About from "./pages/About.vue";
import Projects from "./pages/Projects.vue";
import LoginPage from "./pages/LoginPage.vue";
import BlogPost from "./pages/BlogPost.vue";
import BlogPosts from "./pages/BlogPosts.vue";
import NotFound from "./pages/NotFound.vue";

import AllOfferings from "./pages/services/AllOfferings.vue";
import Adapt from "./pages/services/Adapt.vue";
import Mitig from "./pages/services/Mitig.vue";
import Strategy from "./pages/services/Strategy.vue";
import BusMitig from "./pages/services/BusMitig.vue";

// Admin Blog
import AdminBlogPosts from "./pages/admin/AdminBlogPosts.vue";
import EditBlogPost from "./pages/admin/EditBlogPost.vue";
import VueRouter from "vue-router";
// import AdminMainNavBar from "./pages/admin/layout/AdminMainNavBar.vue";
// import AdminMainFooter from "./pages/admin/layout/AdminMainFooter.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    //Promethium pages

    {
      path: "/",
      name: "Home",
      components: {default: Home, header: MainNavbar, footer: MainFooter},
      meta: {
        title: "Home",
        metaTags: [
          {
            name: "description",
            content:
              "Promethium Carbon is a specialist advisory firm focusing on carbon and climate change. We advise our clients, who included the large mining and metals companies that operate out of South Africa, on how to reposition themselves for the low carbon economy.",
          },
          {
            name: "keywords",
            content:
              "Promethium Carbon, carbon emissions, climate change advisors,  carbon-constrained, Kyoto Protocol, carbon credit project development, carbon foot printing and disclosure",
          },
          {
            property: "og:description",
            content:
              "Promethium Carbon is a specialist advisory firm focusing on carbon and climate change. We advise our clients, who included the large mining and metals companies that operate out of South Africa, on how to reposition themselves for the low carbon economy.",
          },
        ],
      }, //end of meta
    },

    {
      path: "/contact",
      name: "Contact",
      components: {default: Contact, header: MainNavbar, footer: MainFooter},
      props: {footer: {type: "default"}},
      meta: {
        title: "Contact",
        metaTags: [
          {
            name: "description",
            content:
              "Contact Promethium, call us, get director's details, company numbers and  company location",
          },
          {
            name: "keywords",
            content:
              "Promethium Carbon, lets have a chat, contact us, company numbers, director details, postal details",
          },
        ],
      }, //end of meta
    },

    {
      path: "/about",
      name: "About",
      components: {default: About, header: MainNavbar, footer: MainFooter},
      meta: {
        title: "About",
        metaTags: [
          {
            name: "description",
            content:
              "About Promethium company history, team, careers, accreditations and associations",
          },
        ],
      }, //end of meta
    },

    // {
    //   path: "/services",
    //   name: "Services",
    //   components: {
    //     default: AllOfferings,
    //     header: MainNavbar,
    //     footer: MainFooter,
    //   },
    //   meta: {
    //     title: "Services",
    //     metaTags: [
    //       {
    //         name: "description",
    //         content: "Promethium service offerings ",
    //       },
    //       {
    //         name: "keywords",
    //         content:
    //           "pollution prevention, enegry management, carbon tax, carbon offsets, budgets, energy reporting, greenhouse gas, footprint calculation, climate change, cdp, lca, cdm, ver",
    //       },
    //     ],
    //   }, //end of meta
    // },

    {
      path: "/services/adaptation",
      name: "adaptation",
      components: {default: Adapt, header: MainNavbar, footer: MainFooter},
      meta: {
        title: "Climate Change Adaptation",
        metaTags: [
          {
            name: "description",
            content: "Climate Change adaptation",
          },
        ],
      }, //end of meta
    },

    {
      path: "/services/mitigation",
      name: "mitigation",
      components: {default: Mitig, header: MainNavbar, footer: MainFooter},
      meta: {
        title: "Climate Change Mitigation",
        metaTags: [
          {
            name: "description",
            content: "Climate Change mitigations",
          },
        ],
      }, //end of meta
    },
    {
      path: "/services/strategy-and-reporting",
      name: "strategy",
      components: {default: Strategy, header: MainNavbar, footer: MainFooter},
      meta: {
        title: "Strategy and Reporting",
        metaTags: [
          {
            name: "description",
            content: "Strategy and Reporting ",
          },
        ],
      }, //end of meta
    },

    {
      path: "/services/mitigation",
      name: "mitigation",
      components: {default: BusMitig, header: MainNavbar, footer: MainFooter},
      meta: {
        title: "Business Mitigation",
        metaTags: [
          {
            name: "description",
            content: "business mitigations",
          },
        ],
      }, //end of meta
    },

    {
      path: "/projects",
      name: "projects",
      components: {default: Projects, header: MainNavbar, footer: MainFooter},
      meta: {
        title: "Projects",
        metaTags: [
          {
            name: "description",
            content:
              "Projects by Promethium national and company level projects",
          },
          {
            name: "keywords",
            content: "CDM methodologies, projects",
          },
        ],
      }, //end of meta
    },
    {
      path: "/login",
      name: "Login",
      components: {default: LoginPage, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 450}},
      meta: {
        title: "Login",
        metaTags: [
          {
            name: "description",
            content: "login to promethium",
          },
        ],
      }, //end of meta
    },
    {
      path: "/search",
      name: "search",
      components: {
        default: SearchResults,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {header: {colorOnScroll: 450}},
      meta: {
        title: "Search",
        metaTags: [
          {
            name: "description",
            content: "login to promethium",
          },
        ],
      }, //end of meta
    },
    {
      path: "/knowledge-center/:category",
      name: "blog",
      components: {default: BlogPosts, header: MainNavbar, footer: MainFooter},
      meta: {
        title: "Knowledge Center",
        metaTags: [
          {
            name: "description",
            content: "blog post",
          },
        ],
      }, //end of meta
    },
    {
      path: "/knowledge-center/resources/:slug",
      name: "admin-blog-post",
      components: {
        default: BlogPost,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {header: {colorOnScroll: 450}},
      meta: {
        title: "Knowledge Center | Resources",
        metaTags: [
          {
            name: "description",
            content: "blog post about resources",
          },
        ],
      }, //end of meta
      // meta: {
      //   requiresAuth: true,
      // },
    },

    // Admin Routes
    {
      path: "/admin/blog-posts",
      name: "admin-blog-posts",
      components: {default: AdminBlogPosts, header: MainNavbar},
      //props: {header: {colorOnScroll: 450}},
      meta: {
        requiresAuth: true,
        title: "Admin | Blog posts",
      },
    },
    {
      path: "/admin/blog-posts/create",
      name: "admin-blog-posts/create-page",
      components: {
        default: EditBlogPost,
        header: MainNavbar,
        footer: MainFooter,
      },
      //props: {header: {colorOnScroll: 450}},
      meta: {
        requiresAuth: true,
        title: "Admin | Create Blog post",
        metaTags: [
          {
            name: "description",
            content: "create blog post",
          },
        ],
      }, //end of meta
    },
    {
      path: "/admin/blog-post/:slug/edit",
      name: "admin-blog-post/edit-page",
      components: {
        default: EditBlogPost,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {header: {colorOnScroll: 450}},
      meta: {
        requiresAuth: true,
        title: "Admin | Edit Blog post",
        metaTags: [
          {
            name: "description",
            content: "edit blog post",
          },
        ],
      }, //end of meta
    },

    {
      path: "/not-found",
      name: "NotFound",
      components: {default: error404, header: MainNavbar, footer: MainFooter},
      meta: {
        title: "Not found",
      }, //end of meta
    },

    //All other routes should be checked if there is a blog post
    //If the API returns 404, the helper called interceptors.js will redirect to /not-found
    {
      path: "/:slug",
      name: "blog-post",
      components: {default: BlogPost, header: MainNavbar, footer: MainFooter},
      meta: {
        title: "Posts",
        metaTags: [
          {
            name: "description",
            content: "blog post",
          },
        ],
      }, //end of meta
    },

    // {
    //   path: "/:pathMatch(.*)*",
    //   name: "not-found",
    //   components: {default: NotFound, header: MainNavbar, footer: MainFooter},
    //   meta: {
    //     requiresAuth: true,
    //     title: "Not Found",
    //   },
    // },
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {selector: to.hash};
    } else {
      return {x: 0, y: 0};
    }
  },
});

export default router;
