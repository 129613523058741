<template>
    <div class="section section-pricing" style="background-image: url('/img/pm/contact-page-image-background.webp'); background-repeat: no-repeat; background-size: cover; background-position: center">
        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto text-center">
                    <!-- <h2 class="title">Ready to grab Now UI Kit PRO?</h2> -->
                </div>
                <div class="col-md-8 ml-auto mr-auto">
                    <!--
                    <p class="description mb-5 text-center">You have
                        Free Unlimited Updates and
                        Premium Support on each package. You also have
                        30 days to request a refund if you're not happy with your purchase.
                        <br> Read more about
                        <b>
                            <a href="https://www.creative-tim.com/license" target="_blank">licenses here</a>
                        </b>.
                    </p>
                    -->
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <card class="text-center p-3" style="background-color=rgba(0,0,0,0.5); opacity: 0.90;">
                        <h4 class="card-title">Talk to us about your journey <br /> towards the low carbon future</h4>
                        <br />
                        <p class="card-text text-left">Our carbon advisors will help you through the transition to a low carbon, climate resilient economy. We can assist you in the following:
                        </p>
                        <ul class=" text-left"> 
                            <li>Climate Change Adaptation:</li>
                            <ul>
                                <li>Climate Change Risk and Vulnerability Assessments</li>
                                <li>Climate Change Impact Assessments</li>
                                <li>Climate Change Adaption Strategies</li>
                            </ul>
                            <li>Climate Change Mitigation:</li>
                            <ul>
                                <li>Carbon Accounting</li>
                                <li>Planning for Climate Change Mitigation</li>
                                <li>Implementation of Climate Change Mitigation Actions</li>
                                <li>Target setting</li>
                            </ul>
                            <li>Strategy & Reporting:</li>
                            <ul>
                                <li>Strategy Service</li>
                                <li>Corporate Reporting</li>
                                <li>Scenario Planning</li>
                                <li>Carbon And Climate Change Strategy</li>
                            </ul>
                        </ul>
                        <a href="/contact" class="btn btn-primary btn-round mt-4" style="font-size:1.75em">Contact us</a>
                        
                    </card>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  	import card from '@/components/Cards/Card.vue';
	export default {
    	name: 'CtaFooter',
	    components: {
	      card
	    }
    }
</script>