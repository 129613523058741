<template>
  <navbar
    position="fixed"
    menu-classes="ml-auto"
  >

  <!-- Transparent background and onscroll effects on navbar tag -->
    <!-- :transparent="transparent"
    :color-on-scroll="colorOnScroll" -->


    <nav-link style="background-color: #fff !important;" to="/">
      <img src="/img/pm/promethium-carbon-logo.webp" alt="Promethium Carbon Logo" style="max-height: 75px;"/>
    </nav-link>

    <template slot="navbar-menu">
      
      <drop-down
        tag="li"
        title="Climate Change Adaptation"
        class="nav-item"
        style="font-size:1.05em;"
      >
        <nav-link v-for="service in services.filter((service) => {return service.category_id == 1})" :key="service.id" class="dropdown-item" :to="{path: '/services/'+service.category.slug, hash:'#'+service.slug}">
          {{service.title}}
        </nav-link>
      </drop-down>

      <drop-down
        tag="li"
        title="Climate Change Mitigation"
        class="nav-item"
        style="font-size:1.05em;"
      >
        <nav-link v-for="service in services.filter((service) => {return service.category_id == 2})" :key="service.id" class="dropdown-item" :to="{path: '/services/'+service.category.slug, hash:'#'+service.slug}">
          {{service.title}}
        </nav-link>
      </drop-down>

      <drop-down
        tag="li"
        title="Strategy & Reporting"
        class="nav-item"
        style="font-size:1.05em;"
      >
        <nav-link v-for="service in services.filter((service) => {return service.category_id == 6})" :key="service.id" class="dropdown-item" :to="{path: '/services/'+service.category.slug, hash:'#'+service.slug}">
          {{service.title}}
        </nav-link>
      </drop-down>

      <drop-down
        tag="li"
        title="Learn More    "
        class="nav-item"
        style="font-size:1.05em"
      >
        <nav-link class="dropdown-item" to="/projects">
          Projects
        </nav-link>
        <nav-link class="dropdown-item" to="/knowledge-center/resources">
          Knowledge Center
        </nav-link>
        <!-- <nav-link class="dropdown-item" to="/knowledge-center/resources">
          Resources
        </nav-link> -->
        <!-- <nav-link class="dropdown-item" to="/knowledge-center/industry-news">
          Industry News
        </nav-link>
        <nav-link class="dropdown-item" to="/knowledge-center/promethium-in-the-news">
          Promethium in the News
        </nav-link> -->
        <nav-link class="dropdown-item" to="/about">
          About
        </nav-link>
        <nav-link to="/about#history">
          Company History
        </nav-link>
        <nav-link to="/about#team">
          The Team
        </nav-link>
        <nav-link to="/about#accreditations">
          Accreditations and Associations
        </nav-link>
        <nav-link to="/about#careers">
          Careers
        </nav-link>
        <nav-link to="/about#alumni">
          Alumni
        </nav-link>
        <nav-link to="/contact#contact">
          Contact
        </nav-link>
      </drop-down>


      

      <li class="nav-item" style="font-size:1.05em">
        <nav-link class="nav-link py-2 px-3" to="/search">
          <span>Search &#x1F50D;</span>
        </nav-link>
      </li>


      <li class="nav-item" style="font-size:1.05em">
        <a class="nav-link btn btn-primary py-2 px-3" href="/contact">
          <p>Contact</p>
        </a>
      </li>


      <drop-down
        v-if="isLoggedIn"
        tag="li"
        title=""
        class="nav-item"
      >
        <nav-link class="nav-link dropdown-item pl-5" href="/admin/blog-posts">
          Admin
        </nav-link>
        <nav-link>
          <span @click="logout()" id="logout-nav-link">
            <i class="now-ui-icons users_circle-08"></i>
            Log out
          </span>
        </nav-link>
      </drop-down>
    </template>
  </navbar>
</template>

<script>
import axios from "axios";
import { DropDown, NavbarToggleButton, Navbar, NavLink } from "@/components";
import { Popover } from "element-ui";
import { Button, InfoSection, FormGroupInput } from "@/components";
import Vue from "vue";

export default {
  name: "main-navbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
    services: Array,      
    service_list_left: Array,
    service_list_right: Array,
  },
  components: {
    DropDown,
    Navbar,
    NavbarToggleButton,
    InfoSection,FormGroupInput,Button,
    NavLink,
    [Popover.name]: Popover,
  },
  computed: {
    isLoggedIn: {
      get() {
        return this.$store.getters.isLoggedIn;
      },
    },
  },

  data: function() {
    return {
      blog:{name_of_search_item:"",}
    }
  },  

  methods: {
      logout() {
        this.$store.dispatch("logout").then(() => {
          this.$router.push("/login");
        });
      }
  },
  mounted() {
      // 
  },
};
</script>

<style scoped>
</style>
