/*!

 =========================================================
 * Vue Now UI Kit Pro - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-now-ui-kit-pro
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./registerServiceWorker";
//plugins
import BootstrapVue from "bootstrap-vue";
import NowUIKit from "./plugins/now-ui-kit";
import VueGoogleCharts from "vue-google-charts";
import VueSocialSharing from "vue-social-sharing";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import store from "./store";

import interceptorsSetup from "./helpers/interceptors";
interceptorsSetup();

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(NowUIKit);
Vue.use(VueGoogleCharts);
Vue.use(VueSocialSharing);
Vue.use(VueSweetalert2);

//authentication check for routes with requiresAuth using the toke from state
router.beforeEach((to, from, next) => {
  //dynamic titles
  // document.title = process.env.VUE_APP_TITLE + " " + to.name;
  // if (to.params.category) {
  //   document.title += " " + to.params.category;
  // }

  // document.meta= to.meta;
  // console.log(document.meta);

  //start of seo using meta tags

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title =
      process.env.VUE_APP_TITLE + " " + nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title =
      process.env.VUE_APP_TITLE + " " + previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();

  //end of seo

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isLoggedIn) {
      next({
        path: "/login",
        query: {redirect: to.fullPath},
      });
    }
    
    else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
