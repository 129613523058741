<template>
    <div class="wrapper contact-page">
        <div class="page-header page-header-small">
            <parallax class="page-header-image"
                 style="background-image: url('../img/pm/government-adaptation-background-image.webp')">
            </parallax>
            <div class="content-center">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <h1 class="title">Climage Change <br> Adaptation</h1>
                        <!--
                        <h2 class="h4" style="font-size:30px">to major international corporations.</h2>
                        -->
                        <br />
                        <br />
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <!-- <div class="section" style="background: #FFFFFF">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12 mr-auto ml-auto ml-2 text-center mb-5">
                            <h2 class="title">Adaptation Services</h2>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center mx-5">
                        <div class="col-md-4">
                                <h4 class="mt-0">Promethium Carbon today enjoys the position of being a trusted advisor to major international corporations operating out of South Africa.  </h4>
                                <br />
                                <p>We advise our clients in the public sector on policies and actions related to greenhouse gas
                                emissions and climate change. Our services in this regard
                                covers the full range from research underlying adaptation policy development through to policy
                                implementation. Our service delivery builds on our long-standing relationships with .governments
                                and internatio0nal funding organisations.
                                </p>
                        </div>
                        <div class="col-md-4 pl-5">
                                <p>
                                    Promethium Carbon was started in the late 1990’s as a corporate finance consulting company.  By 2003 the decision was taken to refocus the business to environmental finance.  We started doing carbon finance work based on linking the Kyoto Protocol mechanisms to the European Union Emission Trading Scheme even before the Kyoto Protocol came into force.  Promethium Carbon contributed to the establishment of a global carbon market by developing a number of new methodologies on the basis of which projects can be developed under the Clean Development Mechanism of the Kyoto Protocol.
                                </p>
                        </div>
                        <div class="col-md-4 pl-5">
                                <p>
                                    During 2009 the company focus was further developed when we disinvested from all activities that were not focused solely on carbon and climate change. From 2009 onwards Promethium Carbon has been a pure-play carbon company.
                                Through our history, the prime priority has been to assemble a team with a strong multidisciplinary background.  One can only add value in the carbon space by integrating the technical, commercial and regulatory aspects of a business or project. Promethium Carbon’s success has been built on employing competent people capable of dealing with these complex issues and training them up to become carbon specialists.
                                </p>
                        </div>
                    </div>
                </div>
            </div>-->

            <!-- <div class="separator-line separator-primary"></div> -->


            <div v-for="service in filtered_services" :key="service.id">
                <div class="about-office" :id="service.slug">
                    <div class="container">
                        <div class="row text-center">
                            <div class="col-md-10 ml-auto mr-auto">
                                <h2 class="title">{{service.title}}</h2>
                                <h4 class="description">{{service.excerpt}}</h4>
                                <p><span v-html="service.body"></span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="separator-line separator-primary"></div>
            </div>
            
        </div>
        <CtaFooter />
    </div>
</template>
<script>
import axios from "axios";
import { Card, Button, Badge, InfoSection, FormGroupInput, Checkbox, CtaFooter } from '@/components';
import { Select, Option } from 'element-ui';

export default {
    name: 'Services',
    bodyClass: 'about-us',
    components: {
        Card,
        InfoSection,
        Badge,
        CtaFooter,
        [Button.name]: Button,
        [Checkbox.name]: Checkbox,
        [FormGroupInput.name]: FormGroupInput,
        [Select.name]: Select,
        [Option.name]: Option,
    },
    props: {
        services: Array,
    },
    data() {
        return {
            filtered_services: Array,
            form: {
                firstName: '',
                email: '',
                speciality: ''
            }
        }
    },  
    methods: {
        //
    },
    created() {
        this.filtered_services = this.services.filter((service) => {
            return service.category.slug == "adaptation";
        })
    },
    mounted() { 
        //
    },
    updated() { 
        
        //after mounted scroll to the anchor tag
        if(this.$route.hash) {
            const el = document.querySelector(this.$route.hash)
            el && el.scrollIntoView()
        }
    }
}
</script>

<style>

ol, ul {
    text-align: left;
}

</style>
