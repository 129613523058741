<template>
    <div class="wrapper contact-page">
        <div class="page-header page-header-small">
            <parallax class="page-header-image"
                 style="background-image: url('/img/pm/prom-in-news-background.webp')">
            </parallax>
            <div class="content-center">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <h1 class="title" >Projects</h1>
                        <!--
                        <h2 class="h4" style="font-size:30px">to major international corporations.</h2>
                        -->
                        <br />
                        <br />
                    </div>
                </div>
            </div>
        </div>
        <div class="section">

            <div v-for="project in projects" :key="project.id">
                <div class="about-office pt-0" :id="project.slug">
                    <div class="container">
                        <div class="row text-center">
                            <div class="col-md-10 ml-auto mr-auto">

                                <div :key="thumbnail_key" v-if="project.thumbnail_url" class="col-md-12 mt-n5">
                                  <div>
                                    <img :src="project.thumbnail_url" style="height:200px" :alt="project.excerpt"/>
                                  </div>
                                </div>

                                <h2 class="title">{{project.title}}</h2>
                                <h4>{{project.excerpt}}</h4>
                                <div :key="attachment_key">
                                  <div v-for="file in project.attachment_files" :key="attachment_key">
                                    <p>
                                      <a href="#" v-on:click="downloadAttachment(file.uuid,file.file_name,file.file_extension);">
                                        <i class="fas fa-download" style="font-size:12px"></i>
                                        {{ file.file_name }}.{{ file.file_extension }}
                                      </a>
                                    </p>
                                  </div>
                                </div>
                                <p><span v-html="project.body"></span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="separator-line separator-primary"></div>
            </div>
            
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { Card, Button, Badge, InfoSection, FormGroupInput, Checkbox } from '@/components';
import { Select, Option } from 'element-ui';

export default {
    name: 'Projects',
    bodyClass: 'about-us',
    components: {
        Card,
        InfoSection,
        Badge,
        [Button.name]: Button,
        [Checkbox.name]: Checkbox,
        [FormGroupInput.name]: FormGroupInput,
        [Select.name]: Select,
        [Option.name]: Option,
    },
    props: {
        services: Array,
    },
    data() {
        return {
            projects: Array,
            attachment_key: 1,
            thumbnail_key: 0,
            form: {
                firstName: '',
                email: '',
                speciality: ''
            }
        }
    },  
    created() {
        this.loadData();
        // this.filtered_projects = this.services.filter((service) => {
        //     return service.category.slug == "projects";
        // })
    },
    mounted() { 
        //
    },
    methods: {
        loadData: function() {
          this.category = 'projects';
          axios
          .all([
            axios.get(process.env.VUE_APP_API_URL + "/posts?category=" + this.category),
            axios.get(process.env.VUE_APP_API_URL + "/categories"),
            axios.get(process.env.VUE_APP_API_URL + "/status")
          ])
          .then(
            axios.spread((posts, categories, status) => {
              this.projects = posts.data.posts;
              this.categories = categories.data.categories;
              this.status = status.data.status;
              for (let i = 0; i < this.projects.length; i++) {
                this.getAttachedFilesList(this.projects[i].id, i);
                this.getThumbnail(this.projects[i].thumbnail_uuid, i);
              }
            })
          )
          // .catch((error) => console.log(error));

        },
        getAttachedFilesList(post_id, i) {
            axios
            .get(process.env.VUE_APP_API_URL + "/uploads/download/attachment/" + post_id)
            .then((response) => {
                this.projects[i].attachment_files = response.data.all_uploads;
                this.attachment_key ++;
                console.log(this.attachment_key);
            })
        },

        getThumbnail(uuid, project_index) {

            console.log('project index', project_index)

          axios
            .get(process.env.VUE_APP_API_URL + "/uploads/download/public/" + uuid ,
            {
              responseType: 'blob',
            })
            .then((response) => {
              var thumbnail_url = window.URL.createObjectURL(new Blob([response.data]));
              this.projects[project_index].thumbnail_url = thumbnail_url;
              this.thumbnail_key += 1;
            
                console.log('url', thumbnail_url)
            })
        },

        downloadAttachment(uuid,name,extension){
          var fileLink = document.createElement('a');
          fileLink.href = process.env.VUE_APP_API_URL + "/uploads/download/public/" + uuid
          // console.log(fileLink);
          fileLink.setAttribute('download', name+'.'+extension);
          document.body.appendChild(fileLink);
          fileLink.click();
        },
    },
    updated() { 
        
        //after mounted scroll to the anchor tag
        if(this.$route.hash) {
            const el = document.querySelector(this.$route.hash)
            el && el.scrollIntoView()
        }
    }
}
</script>

<style>

ol, ul {
    text-align: left;
}

</style>
