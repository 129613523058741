<template>
    <div id="app">
        <router-view name="header" :services="services" :service_list_left="service_list_left" :service_list_right="service_list_right" :key="navbar_key"/>
        <div>
            <router-view :services="services" :key="body_key"/>
        </div>
        <router-view name="footer"/>
    </div>
</template>
<script>
  import axios from "axios";
  export default {
    data() {
        return {
            services: [],
            service_list_left: [],
            service_list_right: [],
            navbar_key: 1,
            body_key: 1,
        }
    },
    methods: {
        loadData: function() {

        axios
        .all([
            axios.get(process.env.VUE_APP_API_URL + "/posts-services"),
        ])
        .then(
            axios.spread((servicesResponse) => {
                this.services = servicesResponse.data.services;
                for(let i = 0; i < this.services.length; i++) {
                    if (i % 2 == 0) {
                        this.service_list_left.push(this.services[i]);
                    } else {
                        this.service_list_right.push(this.services[i]);
                    }
                }
                this.navbar_key ++;
                this.body_key ++;
            })
        )
        .catch((error) => console.log(error));

        },
    },
    created() {
        this.loadData();
    },

  }
</script>

