<template>
    <div style="min-height: 100vh ;background-image: url('img/pm/mountain-path-w-gradient-cropped.webp'); background-repeat: no-repeat; background-position: center;">
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <img src="img/pm/promethium-logo-white.jpg" height="150px" style="display: block; margin-left: auto; margin-right: auto; background-color: white; border-radius: 5px; padding: 5px 15px 0px 15px" alt="Promethium Carbon Logo" />

        <h3 class="h3-description text-white">Website under construction</h3>
    </div>
</template>

<script>
  import { ProjectMap, Card, InfoSection } from '@/components';
  import { Carousel, CarouselItem, Tooltip } from 'element-ui';

  export default {
    name: 'Home',
    bodyClass: 'presentation-page',
    components: {
      ProjectMap,
      Card,
      InfoSection,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [Tooltip.name]: Tooltip
    },
  }
</script>

<style scoped lang="scss">
    .github-buttons {
        display: flex;
        align-content: center;
    }
</style>
