<template>
    <div class="wrapper presentation-page">

        <div style="padding-top: 20vh ;min-height: 100vh ;background-image: url('img/pm/mountain-path-w-gradient-cropped.webp'); background-repeat: no-repeat; background-position: center;">
            
            <div class="rellax-text-container rellax-text">
                <h1 class="h1-seo" data-rellax-speed="-1" style="letter-spacing: 3px;">404</h1>
            </div>

            <h3 class="h3-description text-white" data-rellax-speed="-1">It’s a journey towards a climate resilient society.
            <br />
            <a class="btn btn-primary mt-5" href="#service-offering">Start</a></h3>
        </div>
        <div class="page-header clear-filter">
            <div class="rellax-header rellax-header-sky" data-rellax-speed="-4">
                <div class="page-header-image" style="background-image: url('img/pm/mountain-path-top-2.jpg')">
                </div>
            </div>
            <div class="rellax-header rellax-header-buildings" data-rellax-speed="0">
                <div class="page-header-image page-header-city"  style="background-image: url('img/pm/mountain-path-bottom-2.png')">
                </div>
            </div>

            <div class="rellax-text-container rellax-text">
                <h1 class="h1-seo" data-rellax-speed="-1" style="letter-spacing: 3px;">The page you're looking for cannot be not found.</h1>
            <!--
                <div class="pro">PRO</div>
            -->
            </div>

            <!-- <h3 class="h3-description rellax-text" data-rellax-speed="-1">The page you're looking for cannot be not found.</h3> -->
            <br />
        </div>

        <div class="page-header clear-filter">
            <div class="rellax-header rellax-header-sky" data-rellax-speed="-4">
                <div class="page-header-image" style="background-image: url('img/pm/mountain-path-top-2.webp')">
                </div>
            </div>
            <div class="rellax-header rellax-header-buildings" data-rellax-speed="0">
                <div class="page-header-image page-header-city"  style="background-image: url('img/pm/mountain-path-bottom-2.webp')">
                </div>
            </div>

            <div class="rellax-text-container rellax-text">
                <h1 class="h1-seo" data-rellax-speed="-1" style="letter-spacing: 3px;">404 Not Found</h1>
            <!--
                <div class="pro">PRO</div>
            -->
            </div>

            <h3 class="h3-description rellax-text" data-rellax-speed="-1">The page you're looking for cannot be not found.</h3>
            <br />
            
            
            <!--
            <h6 class="category category-absolute rellax-text" data-rellax-speed="-1">
                <parallax>
                    Promethium carbon
                </parallax>
            </h6>
            -->
        </div>
        
        <div class="container">
            <div class="our-clients">
                <div class="container">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { ProjectMap, Card, InfoSection } from '@/components';
  import { Carousel, CarouselItem, Tooltip } from 'element-ui';

  export default {
    name: 'Home',
    bodyClass: 'presentation-page',
    components: {
      ProjectMap,
      Card,
      InfoSection,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [Tooltip.name]: Tooltip
    },
  }
</script>

<style scoped lang="scss">
    .github-buttons {
        display: flex;
        align-content: center;
    }
</style>
