<template>
    <div class="wrapper presentation-page">
        <div style="padding-top: 20vh ;min-height: 100vh ;background-image: url('img/pm/mountain-path-w-gradient-cropped.webp'); background-repeat: no-repeat; background-size: cover; background-position: center;">
            
            <div class="rellax-text-container rellax-text">
                <h1 class="h1-seo mt-5" data-rellax-speed="-1" style="letter-spacing: 3px;">NET-ZERO</h1>
            </div>

            <h3 class="h3-description text-white" data-rellax-speed="-1">It’s a journey towards a climate resilient society.
            <br />
            <a class="btn btn-primary mt-5" href="#service-offering">Start</a></h3>
        </div>
        <div class="section section-basic-components">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-md-10 ml-auto mr-auto text-center">
                        <h2>A multi-disciplinary team of carbon and climate change advisors</h2>
                        <p>
                           As climate change knowledge leaders we create best-practice strategic solutions that guide and enable our
                           clients to evolve and gain competitive advantage in the global green economy.  
                           Promethium Carbon today enjoys the position of being a trusted advisor to national governments and major international corporations. 
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="section" style="background: #EEEEEE" id="service-offering">
            <div class="container-fluid">
                <div class="row mt-5 mx-5">
                    <div class="col-md-12 text-center"> 
                        <h2 class="h1 mb-0">Service Offering</h2>
                    </div>
                    <div class="col-md-4 px-0">
                            <info-section title="Climate Change Adaption"
                                          description="We perform Change Risk and Vulnerability Assessments (CCRVA), Climate Change Impact Assessments (CCIA), assist companies in the preparation of climate change adaptation strategies and more."
                            />                          
                            <a class="ml-3 btn btn-primary"  href="/services/adaptation">Read more</a>
                        
                    </div>
                    <div class="col-md-4 px-0">
                            <info-section title="Climate Change Mitigation"
                                          description="Promethium offers carbon accounting services to a variety of customers in both the private and public sector.  We also advise our clients in the implementation of emission reduction and carbon offset projects. "
                            />
                            <a class="ml-3 btn btn-primary"  href="/services/mitigation">Read more</a>
                         
                    </div>
                    <div class="col-md-4 px-0">
                            <info-section title="Strategy & Reporting"
                                          description="We assist companies to develop and assess climate change scenarios and strategies in line with the requirements of the Taskforce for Climate-related Financial Disclosures (TCFD). "
                            />
                             <a class="ml-3 btn btn-primary"  href="/services/adaptation">Read more</a>
                    </div>
                    <!-- <div class="col-md-3">
                        <div class="col-sm-12 text-center">
                            <info-section title="Mitigation Services to Companies"
                                          description="We advise our clients on the development of greenhouse gas mitigation pathways and net-zero
                                targe development. This assistance help companies to gain competitive advantage in the global low-
                                carbon economy. Our services further support companies in the monitoring and reporting of their
                                emissions and mitigation efforts."
                                            
                            />
                           <a class="btn btn-primary"  href="/services/mitigation">Read more</a>
                        </div>
                         
                    </div> -->
                </div>
            </div>
        </div>
        <div class="section section-basic-components">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-md-10 ml-auto mr-auto text-center">
                        <h2>Experience</h2>
                        <p> Promethium Carbon has provided advisory services on a national and company level since 1998. </p>
                        <br />
                        <ProjectMap />
                    </div>
                </div>
                <div class="row mt-5"> 
                    <div class="col-md-12 text-center">
                        <h3 class="h3 mt-5">Clients</h3>
                        <b-carousel
                            id="carousel-fade"
                            controls
                            img-width="1024"
                            img-height="90"
                        >
                            <b-carousel-slide 
                            img-blank
                            >
                                <div class="row">
                                    <div class="col-md-2 ml-auto">
                                        <img v-lazy="'img/logos/accor.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/afrisam.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/aim.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2 mr-auto">
                                        <img v-lazy="'img/logos/anglo-america.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/anglogold.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/appletiser.jpg'" alt=""/>
                                    </div>
                                </div>
                            </b-carousel-slide>
                            <b-carousel-slide 
                            img-blank
                            >
                                <div class="row">
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/assmang.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/backsberg.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/basil-read.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/bedrock.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/cemo.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/cennergi.jpg'" alt=""/>
                                    </div>
                                </div>
                            </b-carousel-slide>
                            <b-carousel-slide 
                            img-blank
                            >
                                <div class="row">
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/corobrik.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/dauphin.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/degrendel.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/enerco.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/enviro.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/esb-international.jpg'" alt=""/>
                                    </div>
                                </div>
                            </b-carousel-slide>

                            <b-carousel-slide 
                            img-blank
                            >
                                <div class="row">
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/exxaro.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/fairview.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/farmsecure.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/fraser-alexander.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/fredarebecca.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/freeworld.jpg'" alt=""/>
                                    </div>
                                </div>
                            </b-carousel-slide>

                            <b-carousel-slide 
                            img-blank
                            >
                                <div class="row">
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/fruits.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/gem-diamonds.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/gibb.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/giz.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/gold-fields.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/graaf.jpg'" alt=""/>
                                    </div>
                                </div>
                            </b-carousel-slide>

                            <b-carousel-slide 
                            img-blank
                            >
                                <div class="row">
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/groupfive.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/growthpoint.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/idc.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/implats.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/inspired-evolution.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/kfw.jpg'" alt=""/>
                                    </div>
                                </div>
                            </b-carousel-slide>

                            <b-carousel-slide 
                            img-blank
                            >
                                <div class="row">
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/konica-minolta.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/kwv.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/lereko.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/liberty.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/mediclinic.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/medscheme.jpg'" alt=""/>
                                    </div>
                                </div>
                            </b-carousel-slide>

                            <b-carousel-slide 
                            img-blank
                            >
                                <div class="row">
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/mtn.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/nampak.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/nbi.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/nedbank.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/nuplanet.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/oceana.jpg'" alt=""/>
                                    </div>
                                </div>
                            </b-carousel-slide>

                            <b-carousel-slide 
                            img-blank
                            >
                                <div class="row">
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/omnia.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/orange-river.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/ormat.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/pdg.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/picknpay.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/pioneerfoods.jpg'" alt=""/>
                                    </div>
                                </div>
                            </b-carousel-slide>


                            <b-carousel-slide 
                            img-blank
                            >
                                <div class="row">
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/plascon.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/ppc1.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/rainbox.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/re.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/remade.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/remgro.jpg'" alt=""/>
                                    </div>
                                </div>
                            </b-carousel-slide>

                            <b-carousel-slide 
                            img-blank
                            >
                                <div class="row">
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/renencom.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/rupert.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/sappi.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/sasol.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/sibanye.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/slr.jpg'" alt=""/>
                                    </div>
                                </div>
                            </b-carousel-slide>

                            <b-carousel-slide
                            img-blank
                            >
                                <div class="row">
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/stellar.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/tongaat.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/trade-aid.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/tronox.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/wbho.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/westfalia.jpg'" alt=""/>
                                    </div>
                                </div>
                            </b-carousel-slide>

                            <b-carousel-slide
                            img-blank
                            >
                                <div class="row">
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/woolworths.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/wwf.jpg'" alt=""/>
                                    </div>
                                    <div class="col-md-2">
                                        <img v-lazy="'img/logos/york.jpg'" alt=""/>
                                    </div>
                                </div>
                            </b-carousel-slide>
                        </b-carousel>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="our-clients">
                <div class="container">
                </div>
            </div>
        </div>
        <CtaFooter />
    </div>
</template>

<script>
  import { ProjectMap, Card, InfoSection, CtaFooter  } from '@/components';
  // import from '@/components/CtaFooter.vue'
  import { Carousel, CarouselItem, Tooltip } from 'element-ui';

  export default {
    name: 'Home',
    bodyClass: 'presentation-page',
    components: {
      ProjectMap,
      Card,
      InfoSection,
      CtaFooter,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [Tooltip.name]: Tooltip
    },
  }
</script>

<style scoped lang="scss">
    .github-buttons {
        display: flex;
        align-content: center;
    }
</style>
