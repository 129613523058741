<template>
  <div class="wrapper contact-page">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/pm/cape-point-medium.webp');"
      >
      </parallax>
            <div class="content-center">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto">
                        <br />
                        <br />
                        <br />
                        <h1 class="title" style="letter-spacing: 4px;">Let's have a chat</h1>
                    </div>
                </div>
            </div>
    </div>

    <div class="main">
      <div class="contact-content">
        <div class="container">
          <div class="row">
            <div class="col-md-5 ml-auto mr-auto">
              <h2 class="title">Contact us</h2>
              <p class="description">
                We’ll help you gain competitive advantage in the global green
                economy.
                <br />
                <br />
              </p>

              <div v-if="show_contact_form">
              <form role="form" id="contact-form" method="POST">
                <label>Your name</label>
                <fg-input
                  name="name"
                  placeholder="Your Name..."
                  v-model="form.name"
                  addon-left-icon="now-ui-icons users_circle-08"
                >
                </fg-input>

                <label>Email address</label>
                <fg-input
                  name="email"
                  placeholder="Email Here..."
                  v-model="form.email"
                  addon-left-icon="now-ui-icons users_circle-08"
                >
                </fg-input>
                <label>Phone</label>

                <fg-input
                  name="phone"
                  placeholder="Number Here..."
                  v-model="form.phone"
                  addon-left-icon="now-ui-icons tech_mobile"
                >
                </fg-input>

                <div class="form-group">
                  <label>Your message</label>
                  <textarea
                    name="message"
                    class="form-control"
                    id="message"
                    rows="6"
                    v-model="form.message"
                  ></textarea>
                </div>

                <div
                  class="g-recaptcha"
                  data-sitekey="6LdvTEUjAAAAALC2O8FRhRFBw3pkf5lxeRtE9lZr"
                  ref="recaptcha"
                ></div>

                <div v-if="error">
                  <span class="text-danger">
                    {{error}}
                  </span>
                </div>

                <div class="submit text-center">
                  <n-button
                    type="submit"
                    class="btn btn-primary mt-3 w-100 shadow-sm"
                    v-on:click.native="contactUs()"
                    round
                    >Submit</n-button
                  >
                </div>
              </form>
              </div>

              <div v-if="!show_contact_form" class="pt-4 pb-3 px-5" style="background: #EEEEEE">
                <p class="description pa-4">
                  Thank you for your message.  We will contact you as soon as possible.
                </p>
              </div>
              
            </div>
            <div class="col-md-5 ml-auto mr-auto">
              <info-section
                type="primary"
                class="mt-5"
                icon="now-ui-icons tech_mobile"
              >
                <h4 class="info-title">Company Numbers</h4>
                <p>
                  0861 CARBON (0861 227 266) <br />
                  Tel : +27 11 706 8185 <br />
                  Fax: +27 86 589 3466
                </p>
              </info-section>

              <info-section
                type="primary"
                icon="business_briefcase-24 now-ui-icons"
              >
                <h4 class="info-title">Director Details</h4>
                <p>
                  <b>Robbie Louw :</b>
                  robbie@promethium.co.za
                </p>
                <p>
                  <b>HJ Swanepoel :</b>
                  hj@promethium.co.za
                </p>
              </info-section>

              <info-section type="primary" icon="now-ui-icons location_pin">
                <h4 class="info-title">Johannesburg Office</h4>
                <p>
                  The Courtyards <br />
                  32 Peter Place <br />
                  Bryanston
                  <br />
                </p>
                <p>
                  <a
                    href="https://www.google.com/maps/dir//FLOOR+BLOCK+2,+Promethuim+Carbon,+THE+COURTYARDS+%7C,+1st,+32+Peter+Place,+%7C,+BRYANSTON%7C,+2060/@-26.0817042,28.0145089,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x1e95737773b7d10d:0xf86a2aa8bb62cf8b!2m2!1d28.0166899!2d-26.0817118!3e0"
                    target="_blank"
                    >Get Directions</a
                  >
                </p>
              </info-section>

              <info-section type="primary" icon="ui-1_email-85 now-ui-icons">
                <h4 class="info-title">Postal Address</h4>
                <p>
                  P O Box 131253, <br />
                  Bryanston, 2021
                </p>
              </info-section>
            </div>
          </div>
        </div>
      </div>
    </div>

    <gmap-map
      id="map"
      class="big-map"
      :center="center"
      :zoom="zoom"
      :options="options"
      map-type-id="terrain"
    >
      <gmap-marker
        v-for="(marker, i) in markers"
        :key="i"
        :position="marker.position"
        @click="openWindow(i)"
      >
        <gmap-info-window
          :position="marker.position"
          @closeclick="marker.window_open = false"
          :opened="marker.window_open"
        >
          <p>
            <b>{{ marker.caption }}</b>
            <br />
            {{ marker.address }}
          </p>
        </gmap-info-window>
      </gmap-marker>
    </gmap-map>
  </div>
</template>
<script>
import { Button, InfoSection, FormGroupInput } from "@/components";
import { MAPS_API_KEY } from "@/constants";
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import axios from "axios";

Vue.use(VueGoogleMaps, {
  load: {
    key: MAPS_API_KEY,
  },
});
export default {
  name: "contact-us",
  bodyClass: "contact-page",
  components: {
    InfoSection,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      error: "",
      show_contact_form: true,
      form: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      zoom: 5,
      center: {
        lat: -30,
        lng: 23,
      },
      markers: [
        {
          window_open: false,
          caption: "Johannesburg office",
          address: "32 Peter Place, Bryanston",
          position: { lat: -26.081708983492565, lng: 28.01450351501419 },
        },
        {
          window_open: false,
          caption: "Cape Town office",
          address: "Greenpoint",
          position: { lat: -33.907653, lng: 18.403898 },
        },
      ],
      options: {
        styles: [
          {
            featureType: "administrative.neighborhood",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "labels.text",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.business",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road.arterial",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road.local",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "labels.text",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    openWindow(index) {
      this.markers[index].window_open = true;
    },
    // execute the recaptcha widget
    executeRecaptcha() {
      this.$refs.recaptcha.execute();
    },

    contactUs: function () {
      axios
        .post(process.env.VUE_APP_API_URL + "/send/email", this.form)
        .then((response) => {
          this.show_contact_form = false; //triggers thank you message
        })
        .catch((err) => {
          this.error = err.response.data.message;
          console.log(err.response.data.message);
        });
    },
  },
};
</script>
<style></style>
