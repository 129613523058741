<template>
  <div class="wrapper blog-post mt-md-5 pt-5" v-if="post">
      <div class="section">
        <div class="container">
          <div class="row d-flex justify-content-center mt-5 pt-5">
            <div class="col-md-12">
              <h1 class="card-title">
                <a>{{ post.title }}</a>
              </h1>
              <div v-if="post.category">
                <a style="font-size:20px">
                  {{ post.category.name }}
                </a>
              </div>
              <p class="mt-2 card-description">{{ post.excerpt }}</p>
              <p v-if="post.category_id > 2" class="author mb-0">
                Published on: {{post.published_at.substr(0,10)}}
              </p>
            </div>
            <div class="col-md-12">
              <div class="row" v-if="isLoggedIn">
                <div class="col-8 pt-3">
                  <p v-if="post.is_published === 0">Status: Draft </p>
                  <p v-if="post.is_published === 1">Status: Published </p>
                </div>
                <div class="col-2 pt-1" v-if="post.is_published === 0">
                  <n-button type="primary" @click.native="modals.classic = true" round simple class="btn float-right button p-0">
                    <i class="fas fa-toggle-off"></i>
                  </n-button>
                </div>
                <div class="col-2 pt-1" v-if="post.is_published === 1">
                  <n-button type="success" @click.native="modals.classic = true" round simple class="btn float-right button p-0">
                    <i class="fas fa-toggle-on"></i>
                  </n-button>
                </div>
                <div class="col-2">
                    <n-button class="btn button pr-2 float-right" round simple @click.native="goToEditPage()">
                      <i class="fas fa-edit"></i>
                    </n-button>
                </div>
              </div>

              
              <!-- Publish Modal -->
              <modal v-if="isLoggedIn" :show.sync="modals.classic" headerClasses="justify-content-center">
                <h4 slot="header" class="title title-up" v-if="post.is_published === 1">Are you sure you want to unpublish?</h4>
                <h4 slot="header" class="title title-up" v-if="post.is_published === 0">Are you sure you want to publish?</h4>
                <div class="form-group col-md-6">
                  <select id="inputState" class="form-control" v-model="post.is_published" hidden>
                    <option
                      v-for="(status, index) in status"
                      :key="index"
                      :value="status.is_published"
                      >{{ status.name }}</option>
                  </select>
                </div>
                <template slot="footer">
                  <n-button class="btn btn-danger" round type="danger" @click.native="modals.classic = false">Close</n-button>
                  <n-button class="btn btn-success" type="success" round @click.native="publishPost(), modals.classic = false" v-if="post.is_published === 0">Publish</n-button>
                  <n-button class="btn btn-primary" round @click.native="publishPost(), modals.classic = false" v-if="post.is_published === 1">Unpublish</n-button>
                </template>
              </modal>
            </div>

            <div v-if="post.thumbnail_uuid" class="col-md-12 my-3">
              <div v-if="attachment_files.length > 0">
                <img
                  class="img rounded"
                  :src="thumbnail" :alt="post.excerpt"
                  height="200"
                  v-on:click="downloadAttachment(attachment_files[0].uuid,attachment_files[0].file_name,attachment_files[0].file_extension);"
                  style="cursor: pointer"
                />
              </div>
              <div v-else>
                <img
                  class="img rounded"
                  :src="thumbnail" :alt="post.excerpt"
                  height="200"
                />
              </div>
            </div>
            <div class="col-md-12">
              <!-- <h3 class="title">Rest of the Story:</h3> -->
              <div v-html="post.body" style="white-space: pre-wrap;"></div>
            </div>

            <div v-if="attachment_files.length > 0" class="col-md-12">
              <h2 class="h4">Downloads</h2>
              <div  v-for="(file, index) in attachment_files" :key="index">
                <p>
                  <a href="#" v-on:click="downloadAttachment(file.uuid,file.file_name,file.file_extension);">
                    <i class="fas fa-download" style="font-size:12px"></i>
                    {{ file.file_name }}.{{ file.file_extension }}
                  </a>
                </p>
              </div>
            </div>
            <div class="col-md-12">
              <h2 class="h4">Share</h2>
              <a class="btn btn-secondary btn-sm mt-2" :href="'mailto:?subject=' +  post.title +'&amp;body=I think you might find this interesting: ' + current_route + '.'">
                <i class="fas fa-envelope my-1" style="font-size: 12px;"></i> Share via email
              </a>
              <ShareNetwork
                  network="twitter"
                  :url="'promethium.co.za/posts/' + post.slug"
                  :title="post.title"
                  :description="post.excerpt"
                  class="btn btn-info btn-sm px-3"
                >
                  <i class="fab fa-twitter pb-1"></i> Share on twitter
              </ShareNetwork>
            </div>
            <div class="col-md-12 mt-5">
              <button
                onclick="history.back()"
                class="btn btn-primary btn-round btn-lg"
              >
                <i class="now-ui-icons arrows-1_minimal-left"></i
                >&nbsp;&nbsp;&nbsp;Back
              </button>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import {
  Card,
  Button,
  Comment,
  Badge,
  InfoSection,
  Checkbox,
  FormGroupInput,
  Switch,
  Modal
} from "@/components";
import axios from "axios";

export default {
  name: "blog-post",
  bodyClass: "blog-post",
  components: {
    Card,
    InfoSection,
    Badge,
    Comment,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [FormGroupInput.name]: FormGroupInput,
    [Switch.name]: Switch,
    [Modal.name]: Modal,
  },
  data() {
    return {
      post: null,
      attachment_files: [],
      categories: null,
      upload: null,
      thumbnail: null,
      thumbnail_uuid: null,
      status: null,
      published_post: 0,
      status_id: 0,
      file: "",
      current_route: "",
      updatedPost: {
        title: "",
        excerpt: "",
        thumbnail: "",
        body: "",
        category_id: 0,
        is_published: 0,
      },
      switches: {
        defaultOn: true,
        defaultOff: false
      },
      modals: {
        classic: false
      }
    };
  },
  methods: {
    selectedFile(event) {
      this.post.thumbnail = event.target.files[0].name;
      this.updatedPost.thumbnail = this.post.thumbnail;
    },
    getThumbnail (uuid) {
      axios
        .get(process.env.VUE_APP_API_URL + "/uploads/download/public/" + uuid ,
        {
          responseType: 'blob',
        })
        .then((response) => {
          this.thumbnail = window.URL.createObjectURL(new Blob([response.data]));
        })
    },
    loadPost() {
      axios
        .get(process.env.VUE_APP_API_URL + "/posts/" + this.$route.params.slug)
        .then((result) => {
          this.post = result.data.post;
          this.upload = result.data.upload;


          //get thumbanil
          var thumbnails = this.post.uploads.filter( (upload) => {
            return upload.purpose == "thumbnail";
          });
          this.thumbnail_uuid = thumbnails[thumbnails.length - 1] ? thumbnails[thumbnails.length - 1].uuid : null;
          if(this.thumbnail_uuid) {
            this.getThumbnail(this.thumbnail_uuid);
          }

          this.getAttachedFilesList(this.post.id);
        })
        .catch((err) => {
          this.post = err;
        });
    },
    getAttachedFilesList(post_id) {
      axios
        .get(process.env.VUE_APP_API_URL + "/uploads/download/attachment/" + post_id)
        .then((response) => {
          this.attachment_files =response.data.all_uploads;
        })
    },
    downloadAttachment(uuid,name,extension){
      var fileLink = document.createElement('a');
      fileLink.href = process.env.VUE_APP_API_URL + "/uploads/download/public/" + uuid
      // console.log(fileLink);
      fileLink.setAttribute('download', name+'.'+extension);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    selectedFile(event) {
      this.post.thumbnail = event.target.files[0].name;
      this.updatedPost.thumbnail = this.post.thumbnail;
    },
    successAlertPublish() {
      this.$swal.fire(
        'Success!',
        'Post published successfully!',
        'success'
      )
    },
    successAlertUnpublish() {
      this.$swal.fire(
        'Success!',
        'Post unpublished successfully!',
        'success'
      )
    },
    failAlert() {
      this.$swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Post not published.'
      })
    },
    goToEditPage() {
      this.$router.push('/admin/blog-post/' + this.$route.params.slug + '/edit')
    },
    publishPost() {
      if (this.post.is_published === 0) {
        this.post.is_published = 1;
        this.successAlertPublish();
      } else {
        this.post.is_published = 0;
        this.successAlertUnpublish();
      }
      axios
        .post(process.env.VUE_APP_API_URL + "/posts/" + this.$route.params.slug + "/publish", {is_published: this.post.is_published})
        .then((response) => {
          this.published_post = response;
        })
        .catch((error) => {
          console.log(error);
          this.failAlert();
        });
    }
  },
  computed: {
    isLoggedIn: {
      get() {
        return this.$store.getters.isLoggedIn;
      },
    },
  },
  created() {
    axios
      .all([
        axios.get(process.env.VUE_APP_API_URL + "/posts/" + this.$route.params.slug),
        axios.get(process.env.VUE_APP_API_URL + "/categories"),
        axios.get(process.env.VUE_APP_API_URL + "/status"),
      ])
      .then(
        axios.spread((post_response, categories_response, status_response) => {
          this.post = post_response.data.post;
          if (this.post.is_published === 0) {
            this.switches.defaultOff;
          } else if (this.post.is_published === 1) {
            this.switches.defaultOn;
          }
          this.categories = categories_response.data.categories;
          this.status = status_response.data.status;
        })
      )
      .catch((error) => {
        console.log(error.response.status);
        if(error.response.status) {
          this.$router.push('/not-found')
        }
      });
  },
  computed: {
    isLoggedIn: {
      get() {
        return this.$store.getters.isLoggedIn;
      },
    },
  },
  mounted() {
    this.loadPost();
    this.current_route = process.env.VUE_APP_BASE_URL + this.$router.currentRoute.path;

    //script for sharing on twitter
    let teamsScript = document.createElement('script')
    teamsScript.setAttribute('src', 'https://teams.microsoft.com/share/launcher.js')
  }
};
</script>
<style scoped>
.section {
  padding: 0px !important;
}
.blogs-1 {
  padding-bottom: 0px !important;
}
.page-header-image {
  background-image: url("~@/assets/img/bg24.jpg");
}
.image-left {
  background-image: url("~@/assets/img/bg35.jpg");
}
.image-right {
  background-image: url("~@/assets/img/project16.jpg");
}
.image-left-bottom {
  background-image: url("~@/assets/img/bg29.jpg");
}
.fas {
  font-size: 30px;
}
.button {
  background: white;
  border: none !important;
}
.fa-edit {
  color: rgb(81, 132, 243);
  font-size: 20px;
}
</style>
