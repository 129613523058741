import axios from "axios";
import { Commit } from "vuex";

// interface authState {
//   status: string;
//   loginError: boolean;
//   token: string;
//   user: userType;
// }

// interface authState {
//   status: string;
//   loginError: boolean;
//   token: string;
//   user: userType;
// }

// interface userType {
//   id: number;
//   permissions: string[];
// }

function state () {
  return {
    status: "",
    loginError: false,
    token: localStorage.getItem("token") || "",
    user: { id: 0, permissions: [""] },
  }
}

// Getters
function isLoggedIn(state) {
  return !!state.token;
}
function authStatus(state) {
  // state.status = "";
  // state.token = "";
  return state.status;
}
function loginError(state) {
  return state.loginError;
}
const getters = {
  isLoggedIn,
  authStatus,
  loginError,
};

const actions = {
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit("auth_request");
      axios({
        url: process.env.VUE_APP_API_URL + "/login",
        data: user,
        method: "POST",
      })
        .then((resp) => {
          // console.log(process.env.VUE_APP_API_URL);
          // console.log(resp.data);
          const token = resp.data.token;
          const token_expiry = resp.data.token_expiry;
          const user_perms = resp.data.permissions;
          const user = resp.data.user["name"];
          localStorage.setItem("token", token);
          localStorage.setItem("token_expiry", token_expiry);
          localStorage.setItem("user_perms", user_perms);
          axios.defaults.headers.common["Authorization"] = token;
          commit("auth_success", token, user);
          resolve(resp);
        })
        .catch((err) => {
          commit("auth_error");
          localStorage.removeItem("token");
          localStorage.removeItem("token_expiry");
          localStorage.removeItem("user_perms");
          reject(err);
        });
    });
  },
  logout({ commit }) {
    return new Promise((resolve) => {
      commit("logout");
      localStorage.removeItem("token");
      localStorage.removeItem("token_expiry");
      delete axios.defaults.headers.common["Authorization"];
      window.location = '/login';
      resolve();
    });
  },
  networkError({ commit }) {
    commit("auth_error");
    commit("login_error");
  },
};

const mutations = {
  auth_request(state) {
    state.status = "loading";
    state.loginError = false;
  },
  auth_success(state, token) {
    state.status = "success";
    state.token = token;
    state.user = { id: 1, permissions: ["perm1", "perm2"] }; //TODO: hard coded for now. update to match snodes auth call back
  },
  auth_error(state) {
    state.status = "error";
  },
  login_error(state) {
    state.loginError = true;
  },
  logout(state) {
    state.status = "";
    state.token = "";
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
