<template>
    <div>
        <h3 class="h3">Project Map</h3>
        <GChart
            :settings="{ packages: ['geochart'] }"
            type="GeoChart"
            :data="chartData"
            :options="chartOptions"
        />
    </div>
</template>

<script>
import { GChart } from 'vue-google-charts'

export default {
    components: {
        GChart
    },
    data () {
    return {
      // Array will be automatically processed with visualization.arrayToDataTable function
      chartData: [
        ['Country','Value',{type:'string',role:'tooltip',p:{html:true}}],
        [{v:'ZA',f:'South Africa'},0,'Emission Reduction <br /> Carbon Disclosure Project<br />Carbon Advisory'],
        [{v:'BW',f:'Botswana'},1,'Carbon Advisory'],
        [{v:'NA',f:'Namibia'},1,'Carbon Advisory'],
        [{v:'GA',f:'Gabon'},1,'Carbon Advisory'],
        [{v:'KE',f:'Kenya'},2,'Emission Reduction<br />Carbon Advisory'],
        [{v:'TZ',f:'Tanzania'},1,'Carbon Advisory'],
        [{v:'CL',f:'Chile'},3,'Carbon Advisory'],
        [{v:'AR',f:'Argentina'},4,'Carbon Advisory'],
        [{v:'PE',f:'Peru'},5,'Carbon Disclosure Project<br />Carbon Advisory'],
        [{v:'CI',f:'Cote D-Ivoire'},6,'Emission Reduction<br />Carbon Advisory'],
        [{v:'GH',f:'Ghana'},7,'Emission Reduction<br />Carbon Disclosure Project<br />Carbon Advisory'],
        [{v:'ZW',f:'Zimbabwe'},8,'Emission Reduction'],
        [{v:'MZ',f:'Mozambique'},9,'Emission Reduction<br />Carbon Advisory'],
        [{v:'MW',f:'Malawi'},10,'Carbon Advisory'],
        [{v:'TZ',f:'Tanzania'},11,'Carbon Advisory'],
        [{v:'UG',f:'Uganda'},12,'Carbon Advisory'],
        [{v:'PG',f:'Papua New Guinea'},13,'Carbon Disclosure Project'],
        [{v:'AU',f:'Australia'},14,'Carbon Disclosure Project '],
        [{v:'US',f:'USA'},15,'Carbon Disclosure Project'],
        [{v:'JP',f:'Japan'},16,'Carbon Advisory'],
        [{v:'UA',f:'Ukraine'},1,'Carbon Advisory']
      ],
      chartOptions: {
        chart: {
          title: 'Promethium Carbons Projects',
        },
        backgroundColor:{ fill:'#FFFFFF' },colorAxis:{ minValue:0, maxValue:16, colors:['#15733B','#177D41','#198746','#1C964E','#0C4222','#0F522A','#115b2f','#188243','#20AB58','#1FA857','#15703A','#10572D','#115b2f','#1A8C48','#146B37','#15733B','#115b2f'] },legend:'none',datalessRegionColor:'#f18433',
        displayMode:'regions',
        enableRegionInteractivity:'true',
        resolution:'countries',
        region:'world',
        tooltip: { textStyle: { color: '#1e1e1e' }, trigger: 'focus', isHtml: true },
      }
    }
  }
}
</script>