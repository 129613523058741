<template>
<div style="min-height:80vh">
        <div class="projects-5">
            <div class="container">
                <div class="row mt-5">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title my-5">What can we help you with?</h2>
                          <div class="content">
                            <div class="search">
                              <input type="text"
                                class="search__input" 
                                v-on:keyup.enter="search()"
                                v-model="name_of_search_item"
                                placeholder="Search by keyword">
                              <button @click="search()" class="search__submit" aria-label="submit search"><i class="fas fa-search"></i></button>
                            </div>
                          </div>
                          <br>
                          <span v-if="loading"><p>Fetching results...</p></span>
                          <p>{{errorMessage}}</p>
                    </div>
                </div>
                <div>
                  <div class="card-body" v-for="(search_result,index) in search_results" :key="index">
                      <router-link :to="`${search_result.slug}`">
                        <h5 class="card-title"><strong>{{search_result.title}} <span v-if="search_result.category_id > 2">({{search_result.published_at.substr(0,10)}})</span></strong>
                        </h5>
                      </router-link>
                      <p class="card-text">
                        {{ search_result.excerpt }} 
                        <router-link :to="`${search_result.slug}`">
                          ... read more 
                        </router-link>
                      </p>
                  </div>
                  <div v-if='!(notFound===null)' class="card-body">
                    <p class="card-text">{{notFound}}</p>
                  </div>
                </div>
            </div>        
        </div>
</div>
</template>

<script>
import axios from "axios";
import { Card, Button, FormGroupInput } from "@/components";

export default {
  props:['items'],
  name: "searchResults",
  bodyClass: "login-page",
  components: {
    Card,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },

data: function() {
    return {
      name_of_search_item:null,
      search_results:null,
      notFound:null,
      loading: false,
      errorMessage:null
    }
  },  

  methods:{
     search() {
        if (this.name_of_search_item==""){
          // this.errorMessage="Please enter something to search for."
          return;}
       this.loading=true;
       this.notFound=null;
       axios
        .get(process.env.VUE_APP_API_URL + "/search", {params:{name_of_search_item:this.name_of_search_item}}) 
        .then(response => {
          if(response.data==""){this.notFound="No matching results"}
          this.search_results=response.data.search_results
        })
        .catch((error) => {
          // console.log(error);
        })
         .finally(() => (this.loading = false));
       },
  },
   computed: {
      isLoggedIn: {
        get() {
          return this.$store.getters.isLoggedIn;
        },
      },
    },
};
</script>

<style lang="scss">
:root {
  --clr-primary: #ff9500;
  --clr-body: #333;
  --clr-bg: #ddd;
}

.search {
  --size: 40px;
  border: 2px solid var(--clr-primary);
  display: flex;
  border-radius: 100px;
  overflow: hidden;
  font-size: 1.25em;
  position: relative;
  width: var(--size);
  height: var(--size);
 
  transition: width 450ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
  padding: 3px;
}

.search__input {
  border: 0;
  padding: .25em 1em;
  flex-grow: 1;
  outline: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: transparent;
  opacity: 0;
  cursor: pointer;
}

.search__submit {
  font-size: 1.5rem;
  margin-left: auto;
  background: 0;
  border: 0;
  cursor: pointer;
  border-radius: 50%;
  transition: background 200ms ease-out;
  width: calc(var(--size) - 10px);
  height: calc(var(--size) - 10px);
  color: var(--clr-body);
}

.search {
  width: 100%;}
  
  .search__input {
    opacity: 1;
    z-index: initial;
    cursor: initial;
    width: calc(100% - var(--size));
  }
  
  .search__submit {
    background: var(--clr-primary);
    color: white;
    box-shadow: 0 0 10px rgba(0,0,0,.15);
    
    &:hover,
    &:focus {
      outline: 0;
      box-shadow: 0 0 10px rgba(0,0,0,.65);
    }
  }
</style>
