<template>
  <div class="wrapper contact-page">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/pm/path-fork.webp'); background-position: top"
      >
      </parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <br />
            <br />
            <br />
            <h1 class="title" style="letter-spacing: 4px;">About Us</h1>
            <!-- <h4>to major international corporations.</h4> -->
          </div>
        </div>
      </div>
       
    </div>
   <div id="history"></div>
    <div class="section pt-0">
      <div
        class="section"
        style="background: #FFFFFF; padding-top: 50px !important;"
      >
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 mr-auto ml-auto ml-2 text-center mb-5">
              <h2 class="title">Company History</h2>
            </div>
          </div>
          <div class="row d-flex justify-content-center mx-5">
            <div class="col-md-10">
              <p>
                Promethium Carbon today enjoys the position of being a trusted
                advisor to major international corporations operating out of
                South Africa. We advise our clients on carbon emissions and
                climate change. These organisations are gaining global
                competitive advantage in the fast-emerging global low-carbon
                economy. Promethium Carbon’s history puts it in an ideal
                position to help you attain success in the carbon-constrained
                global business environment. Promethium Carbon was started in
                the late 1990’s as a corporate finance consulting company. By
                2003 the decision was taken to refocus the business to
                environmental finance. We started doing carbon finance work
                based on linking the Kyoto Protocol mechanisms to the European
                Union Emission Trading Scheme even before the Kyoto Protocol
                came into force. <br /><br />
                Promethium Carbon contributed to the establishment of a global
                carbon market by developing a number of new methodologies on the
                basis of which projects can be developed under the Clean
                Development Mechanism of the Kyoto Protocol. During 2009 the
                company focus was further developed when we disinvested from all
                activities that were not focused solely on carbon and climate
                change. From 2009 onwards Promethium Carbon has been a pure-play
                carbon company. Through our history, the prime priority has been
                to assemble a team with a strong multidisciplinary background.
                One can only add value in the carbon space by integrating the
                technical, commercial and regulatory aspects of a business or
                project. Promethium Carbon’s success has been built on employing
                competent people capable of dealing with these complex issues
                and training them up to become carbon specialists.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="separator-line separator-primary"></div> -->

      <div id="team" class="about-team team-4 bg-light pt-0">
        <div class="container p-5">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center">
              <h2 class="title">The Team</h2>
              <p class="description text-dark">
                Promethium Carbon’s vibrant team of climate change professionals
                ranges from chemical engineers, environmental scientists,
                commercial and financial advisors, to office administrative
                staff and IT support. Promethium Carbon’s strengths stem from
                team work. The company is guided and steered in an interactive
                approach by industry leaders with a solid base of technical
                expertise. Colleagues stimulate and challenge each other
                intellectually to become leaders in their field.
              </p>
              <img src="/img/promethiums-team.jpg" alt="The team at Promethium Carbon">
            </div>
          </div>
          <div class="row mt-5">
            <!-- <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
              <card type="profile" plain>
                <div slot="raw-content" class="row">
                  <div class="col-md-5">
                    <div class="card-image">
                      <a href="#pablo">
                        <img
                          class="img img-raised rounded"
                          src="img/olivia.jpg"
                        />
                      </a>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="card-body">
                      <h4 class="card-title">Ella Evelyn</h4>
                      <h6 class="category">Air Crew Member</h6>
                      <p class="card-description">
                        Robbie is a chemical engineer with a post graduate
                        qualification in Environmental Economics. He has 25
                        years experience in the chemical, mining and industrial
                        sectors in R&D, production and management positions. He
                        is the founder of Promethium and has been involved in
                        clean energy, climate change and greenhouse mitigation
                        since 2003. He has experience in the commercilisation of
                        carbon offsets and reviewing purchase agreements. He
                        specializes in the strategic assessments of climate
                        change risks and impacts on organistations. He developed
                        a climate change strategy that scored 100% on the Dow
                        Jones Sustainability Index for the client.
                      </p>
                      <div class="card-footer">
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-twitter"
                          ><i class="fab fa-twitter"></i
                        ></a>
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-facebook"
                          ><i class="fab fa-facebook-square"></i
                        ></a>
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-google"
                          ><i class="fab fa-google"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
              <card type="profile" plain>
                <div slot="raw-content" class="row">
                  <div class="col-md-5">
                    <div class="card-image">
                      <a href="#pablo">
                        <img
                          class="img img-raised rounded"
                          src="img/emily.jpg"
                        />
                      </a>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="card-body">
                      <h4 class="card-title">Mila Skylar</h4>
                      <h6 class="category">Architect</h6>
                      <p class="card-description">
                        Love cures people - both the ones who give it and the
                        ones who receive it...
                      </p>
                      <div class="card-footer">
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-linkedin"
                          ><i class="fab fa-linkedin"></i
                        ></a>
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-facebook"
                          ><i class="fab fa-facebook-square"></i
                        ></a>
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-dribbble"
                          ><i class="fab fa-dribbble"></i
                        ></a>
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-google"
                          ><i class="fab fa-google"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            </div> -->
            <div class="col-md-12">
              <div class="card-deck mx-auto">
                <div class="card shadow-none">
                  <div class="card-header">
                    <img load="lazy"
                      src="/img/robbie.jpeg"
                      class="card-img"
                      alt="Robbie Louw, Director at Promethium Carbon"
                      srcset=""
                    />
                  </div>
                  <div class="card-body text-center">
                    <h5 class="text-primary">Robbie Louw</h5>
                    <p class="text-primary">Director</p>
                    <p class="text-left">
                      Robbie is a chemical engineer with a post graduate
                      qualification in Environmental Economics. He has 25 years
                      experience in the chemical, mining and industrial sectors
                      in R&D, production and management positions. He is the
                      founder of Promethium and has been involved in clean
                      energy, climate change and greenhouse mitigation since
                      2003. He has experience in the commercilisation of carbon
                      offsets and reviewing purchase agreements. He specializes
                      in the strategic assessments of climate change risks and
                      impacts on organistations. He developed a climate change
                      strategy that scored 100% on the Dow Jones Sustainability
                      Index for the client.
                    </p>
                  </div>
                </div>
                <div class="card shadow-none">
                  <div class="card-header">
                    <img load="lazy" src="/img/hj.jpeg" class="card-img" alt="HJ Swanepoel, Director at Promethium Carbon" srcset="" />
                  </div>
                  <div class="card-body text-center">
                    <h5 class="text-primary">HJ Swanepoel</h5>
                    <p class="text-primary">Director</p>
                    <p class="text-left">
                      HJ Swanepoel is responsible for our Financial and
                      Commercial portfolios. He completed his articles at PWC
                      and subsequently held various financial management roles
                      within a listed services group before joining Promethium.
                      He holds an Honours degree in Accounting from the
                      University of South Africa.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="accreditations" class="container-fluid pb-5">
        <div class="row">
          <div class="col-md-12 mr-auto ml-auto ml-2 text-center mb-5">
            <h2 class="title mt-5">Accreditations and Associations</h2>
          </div>
        </div>
        <div class="row d-flex justify-content-center mx-5">
          <div class="col-md-10">
            <p>
              Promethium Carbon is one of the founding members of the South
              African Clean Development Mechanism Industry Association, and has
              continued to play a role since 2008. To preserve a South African
              carbon neutral logo and make a difference to the wider carbon and
              climate space, Harmke Immink joined the Carbon Protocol of South
              Africa as a board member in 2009. During 2010 Promethium Carbon
              was invited by the Carbon Disclosure Project (CDP) to become a
              consultancy partner of the CDP in South Africa. <br><br> CDP consultancy
              partners demonstrate a commitment to working with CDP to encourage
              high-quality disclosure of climate change related data from global
              corporations. Other client successes in the carbon space include
              registered CDM projects, approved CDM methodologies and issued
              Certified Emission Reductions (CERs). Promethium Carbon advised
              Gold Fields on a transaction to sell CERs. That transaction was
              awarded the European Energy Risk Deal of the Year award in 2010.
              Promethium Carbon was recently invited, as an expert, to be part
              of Technical Committee TC207 for the South African Bureau of
              Standard on the ISO series of Greenhouse Gas (GHG) related
              standards.
            </p>
          </div>
        </div>
      </div>
      <div id="careers" class="container-fluid bg-light pb-5">
        <div class="row">
          <div class="col-md-12 mr-auto ml-auto ml-2 text-center mb-5">
            <h2 class="title mt-5">Careers</h2>
          </div>
        </div>
        <div class="row d-flex justify-content-center mx-5">
          <div class="col-md-10">
            <p>
              We are looking for a dynamic professional to join our growing team
              as a Carbon Advisor. The ideal candidate must be technically
              strong and be a team player with a passion for climate change. A
              technical university degree (engineering or BSc) with at least two
              years work experience is an essential requirement. Experience in
              clean and renewable energy, the Clean Development Mechanism (CDM)
              or greenhouse gas emission reduction projects would be
              advantageous. In addition, we create one training position per
              year for qualified professionals without work experience.
            </p>
          </div>
        </div>
      </div>
      <div id="alumni" class="container-fluid pb-5">
        <div class="row">
          <div class="col-md-12 mr-auto ml-auto ml-2 text-center mb-5">
            <h2 class="title mt-5">Our Alumni</h2>
          </div>
        </div>
        <div class="row d-flex justify-content-center mx-5">
          <div class="col-md-10">
            <p>
              We are very proud to have been an employer since 2004 to a range
              of people who continued, beyond Promethium, to make a difference
              in this world. This network of people showed the impact that a
              small advisory firm with a big heart can have. As a gender
              balanced employer, we maintain roughly a 50/50 split of female and
              male employees. Although we are a key stepping stone for
              first-time job applicants, 30% of employees were mid-career and
              one a retired professional that was ready for a career change.
            </p>
            <br />
            <p>
              Over the last 12 years, 21 professional people moved through
              Promethium and contribute now in diverse sectors.
            </p>
            <div class="col-md-10 mx-auto" style="display:grid; grid-template-columns:repeat(1,1fr); place-items:center">
                <img src="/img/stats-pm.jpeg" class="img ml-5" alt="" srcset="">
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="separator-line separator-primary"></div> -->
      <!-- <div class="row mt-5">
        <div class="col-md-4">
          <div class="info info-hover">
            <div class="icon icon-success icon-circle">
              <i class="now-ui-icons objects_globe"></i>
            </div>
            <h4 class="info-title">Accreditations and Associations</h4>
            <p class="description">
              Cross-account dashboards provide sophisticated reporting for
              enterprise.
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info info-hover">
            <div class="icon icon-info icon-circle">
              <i class="now-ui-icons education_atom"></i>
            </div>
            <h4 class="info-title">Resource Utilization</h4>
            <p class="description">
              CloudCheckr provides summary and detailed usage statistics for
              resources.
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info info-hover">
            <div class="icon icon-primary icon-circle">
              <i class="now-ui-icons tech_watch-time"></i>
            </div>
            <h4 class="info-title">Self-Healing</h4>
            <p class="description">
              CloudCheckr enables users to save money, time, and effort.
            </p>
          </div>
        </div>
      </div> -->
      <!-- <div class="separator-line separator-primary"></div> -->
      <!-- <div class="projects-5">
        <div class="container">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center">
              <h2 class="title">We have many advantages</h2>
              <h4 class="description">
                This is the paragraph where you can write more details about
                your projects. Keep you user engaged by providing meaningful
                information.
              </h4>
              <div class="section-space"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5 ml-auto">
              <card
                type="background"
                raised
                class="card-background-product"
                style="background-image: url('img/project8.jpg')"
              >
                <h2 class="card-title">Social Analytics</h2>
                <p class="card-description">
                  Insight to help you create, connect, and convert. Understand
                  Your Audience's Interests, Influence, Interactions, and
                  Intent. Discover emerging topics and influencers to reach new
                  audiences.
                </p>
                <badge type="neutral">Analytics</badge>
              </card>
            </div>
            <div class="col-md-5 mr-auto">
              <info-section
                type="danger"
                icon="now-ui-icons ui-2_chat-round"
                title="Listen to Social Conversations"
                description="Gain access to the demographics, psychographics, and location of unique people who talk about your brand."
              />

              <info-section
                type="danger"
                icon="now-ui-icons business_chart-pie-36"
                title="Performance Analyze"
                description="Unify data from Facebook, Instagram, Twitter, LinkedIn, and Youtube to gain rich insights from easy-to-use reports."
              />

              <info-section
                type="danger"
                icon="now-ui-icons design-2_ruler-pencil"
                title="Social Conversions"
                description="Track actions taken on your website that originated from social, and understand the impact on your bottom line."
              />
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-5 ml-auto mt-5">
              <info-section
                type="warning"
                icon="now-ui-icons users_single-02"
                title="Work With Any Team"
                description="Whether it’s for work or even the next family vacation, Trello helps your team."
              />

              <info-section
                type="warning"
                icon="now-ui-icons business_chart-bar-32"
                title="A Productivity Platform"
                description="Integrate the apps your team already uses directly into your workflow."
              />

              <info-section
                type="warning"
                icon="now-ui-icons arrows-1_refresh-69"
                title="Always In Sync"
                description="No matter where you are, Trello stays in sync across all of your devices."
              />
            </div>
            <div class="col-md-5 mr-auto">
              <card
                type="background"
                raised
                class="card-background-product"
                style="background-image: url('img/project9.jpg')"
              >
                <h2 class="card-title">Trello lets you work.</h2>
                <p class="card-description text-white">
                  Trello’s boards, lists, and cards enable you to organize and
                  prioritize your projects in a fun, flexible and rewarding way.
                </p>
                <badge type="neutral">Trello</badge>
              </card>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="about-office">
        <div class="container">
          <div class="row text-center">
            <div class="col-md-8 ml-auto mr-auto">
              <h2 class="title">Our office is our second home</h2>
              <h4 class="description">
                Here are some pictures from our office. You can see the place
                looks like a palace and is fully equiped with everything you
                need to get the job done.
              </h4>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <img
                class="rounded img-raised"
                alt="Raised Image"
                src="img/bg27.jpg"
              />
            </div>
            <div class="col-md-4">
              <img
                class="rounded img-raised"
                alt="Raised Image"
                src="img/bg24.jpg"
              />
            </div>
            <div class="col-md-4">
              <img
                class="rounded img-raised"
                alt="Raised Image"
                src="img/bg28.jpg"
              />
            </div>
            <div class="col-md-6">
              <img
                class="rounded img-raised"
                alt="Raised Image"
                src="img/project6.jpg"
              />
            </div>
            <div class="col-md-6">
              <img
                class="rounded img-raised"
                alt="Raised Image"
                src="img/project14.jpg"
              />
            </div>
          </div>
        </div>
      </div> -->
      <div class="about-contact">
        <div class="container">
          <div class="row">
            <div class="col-md-8 mr-auto ml-auto">
              <h2 class="text-center title">Want to work with us?</h2>
              <h4 class="text-center description">
                Divide details about your product or agency work into parts.
                Write a few lines about each one and contact us about any
                further collaboration. We will get back to you in a couple of
                hours.
              </h4>
              <form class="contact-form">
                <!-- <div class="row">
                  <div class="col-md-4">
                    <label>First name</label>
                    <fg-input
                      addon-left-icon="now-ui-icons users_circle-08"
                      v-model="form.firstName"
                      placeholder="First Name..."
                    >
                    </fg-input>
                  </div>
                  <div class="col-md-4">
                    <label>Your email</label>
                    <fg-input
                      addon-left-icon="now-ui-icons ui-1_email-85"
                      v-model="form.email"
                      placeholder="Email Here..."
                    >
                    </fg-input>
                  </div>
                  <div class="col-md-4">
                    <label>Speciality</label>
                    <fg-input>
                      <el-select
                        class="select-default"
                        v-model="form.speciality"
                      >
                        <el-option
                          class="select-default"
                          value="1"
                          label="I'm a Designer"
                        ></el-option>
                        <el-option
                          class="select-default"
                          value="2"
                          label="I'm a Developer"
                        ></el-option>
                        <el-option
                          class="select-default"
                          value="3"
                          label="I'm a Hero"
                        ></el-option>
                      </el-select>
                    </fg-input>
                  </div>
                </div> -->
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto text-center">
                    <a href="/contact" class="btn btn-primary btn-round mt-4 btn-lg">
                      Let's talk
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Card,
  Button,
  Badge,
  InfoSection,
  FormGroupInput,
  Checkbox,
} from "@/components";
import { Select, Option } from "element-ui";

export default {
  name: "about-us",
  bodyClass: "about-us",
  components: {
    Card,
    InfoSection,
    Badge,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [FormGroupInput.name]: FormGroupInput,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        speciality: "",
      },
    };
  },
};
</script>

<style scoped>
@media screen and (width: 992px) {
    .img {
        margin-left: 0px !important;
    }
    .col-md-10 {
        width: 100% !important;
        padding: 0px !important;
    }
}
</style>
