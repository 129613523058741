<template>
  <div class="page-header header-filter">
    <div
      class="page-header-image"
      style="background-image: url('img/pm/mountain-path-w-gradient-medium.jpeg')"
    ></div>
    <div class="content">
      <div class="container">
        <div class="col-md-5 ml-auto mr-auto">
          <card type="login" plain class="mt-5">

            <div class="card-header text-center mb-5">
              <h1>Log in</h1>   
            </div>

            <fg-input
              class="no-border input-lg"
              type="text"
              addon-left-icon="now-ui-icons ui-1_email-85"
              placeholder="Email..."
              name="email"
              v-model="values.email"
            >
            </fg-input>

            <fg-input
              class="no-border input-lg"
              type="password"
              addon-left-icon="now-ui-icons objects_key-25"
              placeholder="Password..."
              name="password"
              v-model="values.password"
            >
            </fg-input>

            <div class="text-center mt-5">
              <span v-if="loginError">
                <p class="text-danger">There was an error logging you in.</p>
              </span>
              <span v-if="authStatus === 'loading'"
                ><p class="text-white">Logging in...</p></span
              >
              <span v-if="authStatus === 'error'">
                <p class="text-danger">Incorrect Credentials</p></span
              >
            </div>
            <!-- href="#pablo" -->
            <div class="card-footer text-center">
              <a
                
                class="btn btn-primary btn-round btn-lg btn-block"
                v-on:click="onSubmit(values)"
                >Log in</a
              >
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Button, FormGroupInput } from "@/components";
import * as Yup from "yup";

export default {
  name: "login-page",
  bodyClass: "login-page",
  components: {
    Card,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
      return {
          values: {
              email: "",
              password: "",
          }
      }
  },
  computed: {
    loginError() {
      return this.$store.getters.loginError;
    },
    authStatus() {
      return this.$store.getters.authStatus;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  setup() {
    const schema = Yup.object().shape({
      email: Yup.string().email().required().label("The Email"),
      password: Yup.string().required().label("The Password"),
    });

    return {
      schema,
    };
  },
  methods: {
    onSubmit(values) {
      let email = values.email;
      let password = values.password;
      this.$store
        .dispatch("login", { email, password })
        .then(() => this.$router.push(process.env.VUE_APP_HOME_URI))
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style></style>
