<template>
    <div class="wrapper presentation-page">

        <div style="padding-top: 20vh ;min-height: 100vh ;background-image: url('img/pm/mountain-path-w-gradient-cropped.webp'); background-repeat: no-repeat; background-position: center;">
            
            <div class="rellax-text-container rellax-text">
                <h1 class="h1-seo mt-5" data-rellax-speed="-1" style="letter-spacing: 3px;">404</h1>
            </div>

            <h3 class="h3-description text-white mt-5" data-rellax-speed="-1">
                The page you're looking for cannot be not found.
            </h3>
        </div>  
    </div>
</template>

<script>
  import { ProjectMap, Card, InfoSection } from '@/components';
  import { Carousel, CarouselItem, Tooltip } from 'element-ui';

  export default {
    name: 'NotFound',
    bodyClass: 'presentation-page',
    components: {
      ProjectMap,
      Card,
      InfoSection,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [Tooltip.name]: Tooltip
    },
  }
</script>

<style scoped lang="scss">
    .github-buttons {
        display: flex;
        align-content: center;
    }
</style>
