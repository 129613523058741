<template>
<div>
  <div class="wrapper contact-page">
     <div class="page-header page-header-small">
        <parallax v-if="category == 'industry-news'" class="page-header-image"
                  style="background-image: url('/img/pm/indusrty-news-background.webp')">
        </parallax>
        <parallax v-if="category == 'resources'" class="page-header-image"
                  style="background-image: url('/img/pm/resources-background.webp')">
        </parallax>
        <parallax v-if="category == 'promethium-in-the-news'" class="page-header-image"
                  style="background-image: url('/img/pm/prom-in-news-background.webp')">
        </parallax>

          <div class="content-center">
              <div class="row">
                    <div v-if="category == 'industry-news'" class="col-md-8 ml-auto mr-auto text-center">
                      <br />
                      <br />
                          <h2 class="title">Industry News</h2>
                          <h4 class="description">Curated articles about industry developments.</h4>
                      </div>
                      <div v-if="category == 'resources'" class="col-md-8 ml-auto mr-auto text-center">
                      <br />
                      <br />
                          <h2 class="title">Resources</h2>
                          <h4 class="description"> Reports and publications by the Promethium team.</h4>
                      </div>
                      <div v-if="category == 'promethium-in-the-news'" class="col-md-8 ml-auto mr-auto text-center">
                      <br />
                      <br />
                          <h2 class="title">Promethium in the News</h2>
                          <h4 class="description"> The latest updates from Promethium.</h4>
                      </div>
              </div>
          </div>
     </div>
     
    <div class="main" :key="component_key">
      <div class="container">
        <div class="section">

          <div v-for="(post, index) in posts" :key="index">
            <div class="card-body" v-if="post.is_published === 1">
              <div class="mb-3">
                <div class="row">
                  <div v-if="post.thumbnail_uuid" class="col-md-12">
                    <img :src="post.thumbnail_url" style="height:200px" :alt="post.excerpt"/>
                  </div>

                  <div class="col-md-12 mt-3">
                      <h5 class="card-title"><router-link :to="`/${post.slug}`">{{ post.title }}</router-link></h5>
                      <!-- <h6> Author: {{ post.author.first_name }} {{ post.author.last_name }}</h6> -->
                      <p> Published: {{post.published_at.substring(0,10)}} </p>
                  </div>
                  <div class="col-md-12">
                    <p class="card-text">{{ post.excerpt }}</p>
                  </div>
                </div>
              </div>
              
              <router-link :to="`/${post.slug}`">
                <h6 class="mt-3">Read more...</h6>
                </router-link>
                <div class="separator-line separator-primary"></div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

</template>
<script>
import { Card, Button,InfoSection, FormGroupInput } from "@/components";
import axios from "axios";
import moment from "moment";

export default {
  name: "blog-posts",
  bodyClass: "blog-posts",
  components: {
    InfoSection,
    Card,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      currentRoute:null,
      uploads:null,
      component_key: 0,
      year: new Date().getFullYear(),
      posts: null,
      categories: null,
      is_published: null,
      category: null,
      headline: null,
    };
  },

  watch: {
    '$route.params.category': {
        handler(newValue) {
            this.loadData();
        },
        immediate: true,
    }
  },

  methods: {

    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },

    getThumbnail(uuid, post_index) {
      axios
        .get(process.env.VUE_APP_API_URL + "/uploads/download/public/" + uuid ,
        {
          responseType: 'blob',
        })
        .then((response) => {
          var thumbnail_url = window.URL.createObjectURL(new Blob([response.data]));
          this.posts[post_index].thumbnail_url = thumbnail_url;
          this.component_key += 1;
        })
    },

    loadData: function() {
      this.category = this.$route.params.category;
      axios
      .all([
        axios.get(process.env.VUE_APP_API_URL + "/posts?category=" + this.category),
        axios.get(process.env.VUE_APP_API_URL + "/categories"),
        axios.get(process.env.VUE_APP_API_URL + "/status")
      ])
      .then(
        axios.spread((posts, categories, status) => {
          this.posts = posts.data.posts;
          this.categories = categories.data.categories;
          this.status = status.data.status;
          for (let i = 0; i < this.posts.length; i++) {
            if(this.posts[i].thumbnail_uuid){
              this.getThumbnail(this.posts[i].thumbnail_uuid, i);
            }
          }
        })
      )
      // .catch((error) => console.log(error));

    },
  },
  // created() {
  //   // this.loadData();
  // },
};
</script>

<style scoped>
.section {
  padding-top: 0px !important;
}
</style>
