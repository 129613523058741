<template>
  <div class="wrapper blog-post" v-if="post">
    <div class="page-header page-header-small">
      <parallax class="page-header-image"></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">{{!!this.$route.params.slug ? 'Edit' : 'Create new'}}</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="button-container">
              <a
                href="/admin/blog-posts"
                class="btn btn-primary btn-round btn-lg"
              >
                <i class="now-ui-icons arrows-1_minimal-left"></i
                >&nbsp;&nbsp;&nbsp;Back To Posts
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="container">
          <div class="col-md-12 mt-3">
                 <div class="card-body bg-light">
                      <form action="" method="POST" class="form-group">
                        <div class="row">
                            <div class="col-md-6">
                                <label for="" class="ml-3"><b>Title</b></label>
                                <input type="text" class="form-control" v-model="updated_post.title">
                                <!-- <span v-if="errors">
                                  <p class="text-danger">Title is required</p>
                                </span> -->
                            </div>
                            <div class="col-md-6">
                                <label for="" class="ml-3"><b>Excerpt</b></label>
                                <input type="text" class="form-control" v-model="updated_post.excerpt">
                                <!-- <span v-if="errors">
                                  <p class="text-danger">Excerpt is required</p>
                                </span> -->
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-md-12">
                              <label for="" class="ml-3"><b>Thumbnail</b></label>
                              <div v-if="thumbnail" class="card-image mx-auto mt-3">
                                  <img class="image" style="width:100px" :src="thumbnail" :alt="post.excerpt">
                              </div>
                              <div>
                                <br>
                                <span class="btn btn-raised btn-round btn-success btn-file">
                                  <span class="fileinput-exists">New thumbnail</span>
                                  <input type="file" name="thumbnail" accept=".jpg,.jpeg,.bmp,.png,.svg,.gif" @change="handleFile($event, 'thumbnail')" />
                                </span>
                                <!-- show new thumbnail -->
                                <label v-if="thumbnail_file_name" for="thumbnail" class="ml-2">{{thumbnail_file_name}}</label>
                                 
                              </div>
                              <!-- <span v-if="errors">
                                <p class="text-danger">Thumbnail is required</p>
                              </span> -->
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-md-12">
                              <label for="" class="ml-3"><b>File attachment</b></label>
                              <div>
                                <span class="btn btn-raised btn-round btn-default btn-file">
                                  <span class="fileinput-exists">New attachment</span>
                                  <input type="file" name="thumbnail" accept=".jpg,.jpeg,.bmp,.png,.svg,.gif,.doc,.docx,.pdf,.xls,.xlsx,.txt" @change="handleFile($event, 'attachment')" />
                                </span>
                                <!-- show new thumbnail -->
                                <label v-if="attachment_file_names.length > 0" for="thumbnail" class="ml-2">{{attachment_file_names.join(', ')}}</label>
                                 
                              </div>
                              <!-- <span v-if="errors">
                                <p class="text-danger">Thumbnail is required</p>
                              </span> -->
                            </div>
                        </div>
                  
                        <div v-if="attachment_files" class="col-md-12">
                          <label for="" class="mt-4"><b>Attached Files</b></label>
                          <div  v-for="(file, index) in attachment_files" :key="index">
                            <p>{{ file.file_name }}.{{ file.file_extension }}
                              <n-button type="primary" @click.native="modals[index] = true" class="btn button p-1 ml-2"><i class="fas fa-solid fa-trash"></i></n-button>
                            </p>
                           
                            <modal v-if="modals[index]" :show.sync="modals[index]" headerClasses="justify-content-center">
                              <h4 slot="header" class="title title-up">Delete attachement</h4>
                               <div class=" text-center">
                                  <p>You are about to delete this file attachment:</p>
                                  <p>{{ file.file_name }}.{{ file.file_extension }}</p>
                                  <p><strong>Are you sure you want to delete?</strong></p>
                               </div>
                             
                              <template slot="footer">
                                <n-button class="btn btn-danger" round type="danger" @click.native="modals[index] = false">Cancel</n-button>
                                <n-button class="btn btn-primary" round @click.native="deleteAttachment(file.uuid), modals[index] = false">Delete</n-button>
                              </template>
                            </modal>
                          </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-md-12 mx-auto">
                                <label for="" class="ml-3"><b>Body</b></label>
                                <!-- <textarea type="text" class="form-control" v-html="updated_post.body" v-model="updated_post.body"></textarea> -->
                                <div class="my-2">
                                    <div ref="editor"></div>
                                </div>
                                <!-- <span v-if="errors">
                                  <p class="text-danger">Body is required</p>
                                </span> -->
                            </div>
                        </div>
                        <div class="row mt-3">
                        <div class="form-group col-md-4">
                            <label for="" class="ml-3"><b>Category</b></label>
                            <select id="inputState" class="form-control" v-model="updated_post.category_id">
                                <option
                                v-for="(category, index) in categories"
                                :key="index"
                                :value="category.id"
                                >{{ category.name }}</option>
                            </select>
                            <!-- <span v-if="errors">
                              <p class="text-danger">Category is required</p>
                            </span> -->
                            </div>
                            <div class="form-group col-md-4">
                              <label for="" class="ml-3"><b>Published</b></label>
                              <select id="inputState" class="form-control" v-model="updated_post.is_published">
                                  <option
                                  v-for="(status, index) in status"
                                  :key="index"
                                  :value="status.is_published"
                                  >{{ status.name }}</option>
                              </select>
                              <!-- <span v-if="errors">
                                <p class="text-danger">Status is required</p>
                              </span> -->
                            </div>
                            <div class="col-md-4">
                              <!-- input with datetimepicker -->
                              <div class="form-group">
                                  <label class="label-control ml-3"><b>Published At</b></label>
                                  <input type="date" class="form-control datetimepicker" v-model="updated_post.published_at" />
                              </div>
                            </div>
                        </div>
                    </form>

                    <div class="errors" v-if="errors">
                      <div class="error">
                        <span>
                          <p class="text-danger">Oops, Something went wrong.</p>
                          <ol class="text-danger">
                            <li v-for="(error, index) in errors" :key="index">{{error.join(" ")}}</li>
                          </ol>
                        </span>
                      </div>
                    </div>

                    <a class="btn btn-secondary text-white btn-round" href="/admin/blog-posts">Back</a>
                    <n-button v-if="!!this.$route.params.slug" type="submit" class="btn btn-primary text-white float-right" v-on:click.native="updatePost()" round>Update</n-button>
                    <n-button v-else type="submit" class="btn btn-primary text-white float-right" v-on:click.native="createPost()" round>Create</n-button>
                    
                     <!-- <button style="width:50px; height:50px" @click="uploadFiles(post.id)" ></button> -->
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Card,
  Button,
  Comment,
  Badge,
  Modal,
  InfoSection,
  Checkbox,
  FormGroupInput
} from "@/components";
import axios from "axios";
import Quill from "quill";
import moment from "moment";

export default {
  name: "edit-post",
  bodyClass: "blog-post-edit",
  components: {
    Card,
    InfoSection,
    Badge,
    Comment,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Modal.name]: Modal,
    [FormGroupInput.name]: FormGroupInput,
  },
  
   computed: {
      user: {
          get() {
              return this.$store.getters.user;
          }
      },
  },
  
  data() {
    return {
      attachment_files: [],
      post: null,
      categories: null,
      status: null,
      thumbnail_uuid: null,
      thumbnail: null,
      thumbnail_file_name: "",
      attachment_file_names: [],
      file: "",
      modals: {
        0: false,
        1: false,
        2: false,
        3: false,
      },
      files: [],
      file_name:"",
      upload_thumbnail:"thumbnail",
      upload_attachment:"attachment",
      purpose:null,
      errors: null,
      editor: null, // Quill editor
      quill_text: "",
      updated_post: {
          title: "",
          excerpt: "",
          thumbnail: "",
          body: "",
          category_id: 0,
          is_published: 0,
          published_at: null
      },
    };
  },
  mounted: function() {
    this.getData();
  },
  methods: {
    selectedFile(event) {
      this.updated_post.thumbnail = event.target.files[0].name;
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    initializeQuill() {
        this.editor = new Quill(this.$refs.editor, {
            modules: {
                toolbar: [
                    [{ header: [1, 2, 3, false] }],
                    ['bold', 'italic', 'underline'],
                    ['link'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                ]
            },
            theme: 'snow',
            formats: ['bold', 'underline', 'header', 'italic','link','list']
        });

        this.quill_text = this.updated_post.body;
        this.loadQuill();

    },
    loadQuill() {
        this.editor.root.innerHTML = this.quill_text; // Initiation value

        this.editor.on('text-change', () => {
          this.updated_post.body = this.editor.root.innerHTML;
        });

    },
    getData() {
      //get categories and stutuses
      axios
        .all([
          axios.get(process.env.VUE_APP_API_URL + "/categories"),
          axios.get(process.env.VUE_APP_API_URL + "/status"),
        ])
        .then(
          axios.spread((categories, status) => {
            this.categories = categories.data.categories;
            this.status = status.data.status;
          })
        )
        // .catch((error) => console.log(error));

      //get blog post
      if(!!this.$route.params.slug) {
        axios
        .all([
          axios.get(process.env.VUE_APP_API_URL + "/posts-all/" + this.$route.params.slug),
        ])
        .then(
          axios.spread((post) => {
            this.post = post.data.post;
            this.updated_post = this.post;
            this.updated_post.published_at = this.formatDate(this.updated_post.published_at);

            //get thumbanil
            var thumbnails = this.post.uploads.filter( (upload) => {
              return upload.purpose == "thumbnail";
            });
            this.thumbnail_uuid = thumbnails[thumbnails.length - 1] ? thumbnails[thumbnails.length - 1].uuid : null;
            if(this.thumbnail_uuid) {
              this.getThumbnail(this.thumbnail_uuid);
            }

            this.getAttachedFilesList(this.post.id);
            setTimeout( (timer) => {
              this.initializeQuill();
            }, 1000);
          })
        )
        // .catch((error) => console.log(error));
      } else {
        this.post = this.updated_post;
        setTimeout( (timer) => {
          this.initializeQuill();
        }, 1000);
      }
    },
    getThumbnail (uuid) {
      axios
        .get(process.env.VUE_APP_API_URL + "/uploads/download/public/" + uuid ,
        {
          responseType: 'blob',
        })
        .then((response) => {
          this.thumbnail = window.URL.createObjectURL(new Blob([response.data]));
        })
    },

    getAttachedFilesList(post_id) {
      axios
        .get(process.env.VUE_APP_API_URL + "/uploads/download/attachment/" + post_id)
        .then((response) => {
          this.attachment_files = response.data.all_uploads;
          for(let i = 0; i < response.data.all_uploads.length; i++) {
            this.attachment_files[i].show_delete_modal = false;
            this.attachment_file_names.push(
              response.data.all_uploads[i].file_name + '.' + response.data.all_uploads[i].file_extension
            )
          }
        })
    },


    showDeleteModal(index){
      this.attachment_files[index].show_delete_modal = true;
    },

    deleteAttachment(uuid){
        axios
        .delete(process.env.VUE_APP_API_URL + '/uploads/remove/'+ uuid)
        .then(() => {
            this.successAlertDeleted();
             setTimeout( (timer) => {
               window.location.reload();
            }, 2000); 
        })
        .catch(err => {
          this.errors = err.response.data.errors;
        });
    },

    // Check form for validation errors
    checkForm: function (e) {
        if (!this.updated_post.title) {
          this.errors.push('Title is required.');
        }
         if (!this.purpose) {
          this.errors.push('Upload type is required.');
        }
        if (!this.updated_post.excerpt) {
          this.errors.push('Excerpt is required.');
        }
        if (!this.updated_post.thumbnail) {
          this.errors.push('Thumbnail is required.');
        }
        if (!this.updated_post.body) {
          this.errors.push('Body is required.');
        }
        if (!this.updated_post.category_id) {
          this.errors.push('Category is required.');
        }
        if (!this.updated_post.is_published) {
          this.errors.push('Status is required.');
        }

        e.preventDefault();
    },
    handleFile(event, purpose) {
      // this.thumbnail=event.target.files[0]
        if(event.target.files[0]) {
            var file_name_w_extension = event.target.files[0].name;

            this.files.push({
              file : new Blob([event.target.files[0]]),
              file_extension : file_name_w_extension.split('.').pop(),
              file_name : file_name_w_extension.substring(0, file_name_w_extension.lastIndexOf('.')),
              purpose: purpose,
            });

            this.upload_ready = true;

            //show new thumbnail name if relevant
            if(purpose == "thumbnail") {
              this.thumbnail_file_name = file_name_w_extension;

              //hide old thumbnail
              this.thumbnail = "";
            }

            if(purpose == "attachment"){
              this.attachment_file_names.push(file_name_w_extension);
            }

        } else {
            this.upload_ready = false;
        }
    },
    createPost() {
      let formData = new FormData();
      formData.append('thumbnail', this.post.thumbnail);
      this.post.category_id = parseInt(this.post.category_id);
      this.post.is_published = parseInt(this.post.is_published);
      axios
        .post(process.env.VUE_APP_API_URL + "/posts", {
          title: this.updated_post.title,
          excerpt: this.updated_post.excerpt,
          body: this.updated_post.body,
          category_id: this.updated_post.category_id,
          is_published: this.updated_post.is_published,
          published_at: this.updated_post.published_at,
        })
        .then((response) => {
          this.post = response.data.post;

          this.uploadFiles(this.post.id); //push to new route after uploads

          this.successAlertPost();
        })
        .catch(err => {
          this.errors = err.response.data.errors;
        });
    },
    updatePost() {
      let formData = new FormData();
      formData.append('thumbnail', this.post.thumbnail);
      this.post.category_id = parseInt(this.post.category_id);
      this.post.is_published = parseInt(this.post.is_published);
      axios
        .patch(process.env.VUE_APP_API_URL + "/posts/" + this.$route.params.slug, {
          title: this.updated_post.title,
          excerpt: this.updated_post.excerpt,
          body: this.updated_post.body,
          category_id: this.updated_post.category_id,
          is_published: this.updated_post.is_published,
          published_at: this.updated_post.published_at,
        })
        .then((response) => {
          this.post = response.data.post;

          this.uploadFiles(this.post.id); //push to new route after uploads

          this.successAlertPost();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
        });
    },
    uploadFiles(post_id) {

      for(let i = 0; i < this.files.length; i++) {
        let form_data = new FormData();
        form_data.append("fqcn", "Modules\\Blog\\Entities\\Post"); // = uploadable type = model path on back end
        form_data.append("uploadable_id",this.post.id); 
        form_data.append("folder_path", "/blog-posts")
        form_data.append("file", this.files[i].file);
        form_data.append("file_name", this.files[i].file_name);
        form_data.append("purpose", this.files[i].purpose);
        form_data.append("file_extension", this.files[i].file_extension);
        axios
          .post(
              process.env.VUE_APP_API_URL + "/uploads/upload", 
              form_data,
              {
              headers: {
                  "Content-Type": "multipart/form-data",
              },
              }
          )
          .then((response) => {
            // this.successAlert();
          })
          .catch((errors) => {
            // console.log(errors);
            // this.failAlert();
          });
      }

      this.$router.push("/admin/blog-posts");
    },
    successAlert() {
      this.$swal.fire(
        'Success!',
        'Post updated and Thumbnail uploaded successfully!',
        'success'
      )
    },
    successAlertPost() {
      this.$swal.fire(
        'Success!',
        'Post updated successfully!',
        'success'
      )
    },
    successAlertDeleted() {
      this.$swal.fire(
        'Success!',
        'File attachment deleted successfully!',
        'Deleted'
      )
    },
    failAlert() {
      this.$swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong! Check that you have selected a thumbnail.'
      })
    },
  },
};
</script>
<style scoped>
.section {
  padding: 0px !important;
}
.blogs-1 {
  padding-bottom: 0px !important;
}
.page-header-image {
  background-image: url("~@/assets/img/bg24.jpg");
}
.image-left {
  background-image: url("~@/assets/img/bg35.jpg");
}
.image-right {
  background-image: url("~@/assets/img/project16.jpg");
}
</style>