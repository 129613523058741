<template>
  <div class="wrapper blog-posts">
    <div class="main">
      <div class="container">
        <div class="section">
          <div class="row mt-5 pt-5"> 
            <div class="col-8 mt-5"> 
              <h3 class="title">Admin View of Articles and Services</h3>
            </div>
            <div class="col-4 mt-5"> 
              <a href="/admin/blog-posts/create">
                <n-button
                  type="primary"
                  class="float-right mt-4"
                  round
                  >Create New
                </n-button>
              </a>
            </div>
          </div>
          <br />
                <b-table v-if="posts" striped hover :items="posts" :fields="fields">
                  <template #cell(category)="data">
                    {{data.item.category ? data.item.category.name : ''}}
                  </template>
                  <template #cell(published)="data">
                    <div class="col-md-6 p-1" v-if="data.item.is_published === 0">
                      <n-button type="primary"  v-b-modal="data.item.slug" round simple class="btn button p-0">
                        <i class="fas fa-toggle-off"></i>
                      </n-button>
                      </div>
                      <div class="col-md-6 p-1" v-if="data.item.is_published === 1">
                      <n-button type="success"  v-b-modal="data.item.slug" round simple class="btn button p-0">
                        <i class="fas fa-toggle-on"></i>
                      </n-button>
                    </div>

                    <!-- Publish Modal -->
                    <b-modal :id="data.item.slug" :ref="data.item.slug" headerClasses="justify-content-center" hide-footer hide-header>
                      <template>
                        <h4 v-if="data.item.is_published == 1">Are you sure you want to unpublish {{data.item.title}}?</h4>
                        <h4 v-if="data.item.is_published == 0">Are you sure you want to publish {{data.item.title}}?</h4>
                        <div class="form-group col-md-6">
                          <select id="inputState" class="form-control" v-model="data.item.is_published" hidden>
                            <option
                              v-for="(status, index) in status"
                              :key="index"
                              :value="status.is_published"
                              >{{ status.name }}</option>
                          </select>
                        </div>
                      </template>
                        <n-button class="btn btn-danger" round type="danger" @click.native="hideModal(data.item)">Close</n-button>
                        <n-button class="btn btn-primary float-right" round @click.native="publishPost(data.item), hideModal(data.item)" v-if="data.item.is_published === 1">Unpublish</n-button>
                        <n-button class="btn btn-success float-right" type="success" round @click.native="publishPost(data.item), hideModal(data.item)" v-if="data.item.is_published === 0">Publish</n-button>
                    </b-modal>

                  </template>
                  <template #cell(published_at)="data">
                    <p v-if="data.item.is_published === 0">{{data.item.published_at ? formatDate(data.item.published_at) : "Draft"}}</p>
                    <p v-if="data.item.is_published === 1">{{formatDate(data.item.published_at)}}</p>
                  </template>
                  <template #cell(Actions)="data">
                    <div class="row d-flex justify-content-end">
                      <div v-if="data.item.is_published == 1" class="col-md-6">
                        <a :href="`/${data.item.slug}`"><n-button type="warning" icon round class="mx-0"><i class="fas fa-eye"></i></n-button></a>
                      </div>
                      <div class="col-md-6">
                        <a :href="`/admin/blog-post/${data.item.slug}/edit`"><n-button type="info" icon round class="mx-0"><i class="fas fa-edit"></i></n-button></a>
                      </div>
                    </div>
                  </template>
                </b-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Button, FormGroupInput, Modal } from "@/components";
import axios from "axios";
import moment from "moment";

export default {
  name: "blog-posts",
  bodyClass: "blog-posts",
  components: {
    Card,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [Modal.name]: Modal,
  },
  data() {
    return {
      file: "",
      post: {
        title: "",
        excerpt: "",
        thumbnail: "",
        body: "",
        category_id: 0,
        is_published: 0,
      },
      modals: {
        classic: false
      },
      year: new Date().getFullYear(),
      posts: null,
      categories: null,
      status: null,
      fields: [
          {
            key: 'title',
            sortable: true
          },
          {
            key: 'category',
            sortable: true
          },
          {
            key: 'published',
            sortable: true
          },
          {
            key: 'published_at',
            label: 'Published at',
            sortable: true,
            thStyle: { width: "15%" },
          },
          {
            key: 'Actions',
            sortable: false
          },
        ],
    };
  },
  mounted: function() {
    this.getData();
  },
  methods: {
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    selectedFile(event) {
      this.post.thumbnail = event.target.files[0].name;
    },
    showModal(post) {
      this.$refs[post.slug].show();
    },
    hideModal(post) {
      this.$refs[post.slug].hide();
    },
    getData() {
      axios
        .all([
          axios.get(process.env.VUE_APP_API_URL + "/posts-all"),
          axios.get(process.env.VUE_APP_API_URL + "/categories"),
          axios.get(process.env.VUE_APP_API_URL + "/status"),
        ])
        .then(
          axios.spread((posts, categories, status) => {
            this.posts = posts.data.posts;
            this.categories = categories.data.categories;
            this.status = status.data.status;
          })
        )
        .catch((error) => console.log(error));
    },
    successAlertPublish() {
      this.$swal.fire(
        'Success!',
        'Post published successfully!',
        'success'
      )
    },
    successAlertUnpublish() {
      this.$swal.fire(
        'Success!',
        'Post unpublished successfully!',
        'success'
      )
    },
    failAlert() {
      this.$swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong.'
      })
    },
    publishPost(post) {
     
        if (post.is_published == '0') {
            post.is_published = 1;
           
         
        } else {
            post.is_published = 0;
            // this.successAlertUnpublish();
        }
      axios
        .post(process.env.VUE_APP_API_URL + "/posts/" + post.slug + "/publish", {is_published: post.is_published})
        .then((response) => {
          this.published_post = response;
          this.successAlertPublish();
        })
        .catch((error) => {
          this.failAlert();
        });
    },
    createPost: function() {
      let formData = new FormData();
      formData.append('thumbnail', this.post.thumbnail);
      this.post.category_id = parseInt(this.post.category_id);
      this.post.is_published = parseInt(this.post.is_published);
      axios
        .post(process.env.VUE_APP_API_URL + "/posts", this.post)
        .then((response) => {
          this.post = response;
        })
        .catch((err) => {
          this.post = err;
        });
    },
  },
};
</script>

<style scoped>
.section {
  padding-top: 0px !important;
}
.page-header-image {
  background-image: url("~@/assets/img/bg44.jpg");
}
.team-3 {
  background-image: url("~@/assets/img/bg26.jpg");
}
.fa-toggle-off, .fa-toggle-on {
  font-size: 30px;
}
.button {
  background: transparent;
  border: none !important;
}
</style>
