import axios from "axios";
import store from "../store";
import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export default function setup() {
  axios.interceptors.request.use(
    function (config) {
      // const token = this.$store.getters.login.token;
      // const token = context.rootState.login.token;
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function (err) {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(undefined, function (err) {
    if (
      err.response.status == 401 ||
      err.response.data.error === "401 Unauthorized"
    ) {
      store.dispatch("logout");
    }
    return Promise.reject(err);
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (!error.response) {
        store.dispatch("networkError");
        console.log("Please check your internet connection.");
      }

      return Promise.reject(error);
    }
  );
}