import { render, staticRenderFns } from "./MainNavbar.vue?vue&type=template&id=182001a3&scoped=true"
import script from "./MainNavbar.vue?vue&type=script&lang=js"
export * from "./MainNavbar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "182001a3",
  null
  
)

export default component.exports